import React, { useEffect, useState } from "react";

import Header from "../Component/Header";
import Footer from '../Component/Footer';
import './AboutSnepitech.css';
import 'animate.css';
import WOW from 'wowjs';

import { Col, Container, Row } from "react-bootstrap";

import Rounds from "../Images/Home/Rounds.webp";
import Rounds2 from "../Images/Home/Round2.webp";

import ArrowWhite from "../Images/Home/ArrowWhite.png";
import ArrowBlack from "../Images/Home/ArrowBlack.png";

import SnepiGrp from "../Images/AboutSnepitech/SnepitechGrp.png"
import linebtn from "../Images/AboutSnepitech/linebtn.png";
import linebtn2 from "../Images/AboutSnepitech/linebtn2.png";
import Mission from "../Images/AboutSnepitech/mission.png";
import eye from "../Images/AboutSnepitech/eyes.png";

import SuccessFullMan from "../Images/AboutSnepitech/SuccessfullBusinessMan.png";
import i1 from "../Images/AboutSnepitech/i1.png";
import i2 from "../Images/AboutSnepitech/growthLine.png";
import i3 from "../Images/AboutSnepitech/up.png";
import i4 from "../Images/AboutSnepitech/list.png";

import BeforeFooter from "../Component/BeforeFooter";
import { Link } from "react-router-dom";
import { PiMouseSimple } from "react-icons/pi";
import Seo from "../Component/Seo";

function AboutSnepitech() {
    const [scrollmouseClass, setScrollmouseClass] = useState("notShown");

    const handleScroll = () => {
        const currentScrollTop = window.scrollY;
        const showThreshold = 400; // Adjust this threshold as needed

        if (currentScrollTop > showThreshold) {
            setScrollmouseClass("Shown");
        } else {
            setScrollmouseClass("notShown");
        }
    };

    const scrollevent = () => {
        const currentScrollTop = window.scrollY;
        if (currentScrollTop > 400) {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    useEffect(() => {
        new WOW.WOW().init();
      }, []);

    return (
        <>
    <Seo
        title="Top Software Companies in Mota Varachha, Surat " 
        description="Best Software Companies in Mota Varachha, Surat "
        keywords="Varachha ,Surat , Mota ,Companies , Software , snapchat ,Top Software company in Mota Varachha"
      />
        <div onScroll={handleScroll}>
            <div className={`upperMouse ${scrollmouseClass}`}>
                <PiMouseSimple className="upperMouseIcon" onClick={scrollevent} />
            </div>
            <div className={`MainHome MainHome1 MainHomeAbout`}>
                <Header />
                <Container>
                    <Row className="wow animate__animated animate__fadeInUp"
                        style={{ animationDuration: '2s' }}
                    >
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="mt-5 pt-5">
                            <h1 className="ServiceSliderHead">
                                ABOUT SNEPITECH
                            </h1>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="py-5">
                            <h3 className="ServiceSliderPara">
                                Serving customers with the latest tech-infused software solutions, our innovation is what sets us apart from others. We are your partners in technical transformation.
                            </h3>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <Link to={"/RequestQuote"} style={{ textDecoration: "none" }}>
                                <button variant="outline-success" className="AboutButton">
                                    Get a Quote
                                    <img src={ArrowWhite} alt={ArrowWhite} className="ms-3 arrow" />
                                </button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="mt-5 Line3Parent">
                <Container fluid>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div className="AboutServices3">
                                <div className="Rounds"><img src={Rounds} alt="rounds" /></div>
                                <div className="Rounds2"><img src={Rounds2} alt="rounds2" /></div>
                            </div>
                            <Container>
                                <Row>
                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-center">
                                        <img src={SnepiGrp} alt={SnepiGrp} className="SnepigrpImage wow animate__animated animate__flipInX"
                                            style={{ animationDuration: '2s' }} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="mt-5 Line3Parent">
                <Container fluid>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div className="AboutServices3">
                                <div className="Rounds"><img src={Rounds} alt="rounds" /></div>
                                <div className="Rounds2"><img src={Rounds2} alt="rounds2" /></div>
                            </div>
                            <Container>
                                <Row>
                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                        <h1 className="ProcessHead1 wow animate__animated animate__fadeInLeft">Who We Are</h1>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="wow animate__animated animate__fadeInUp">
                                        <h3 className="ServiceSliderPara ServiceSliderParaAbout">
                                            Snepitech is one of the most advanced software solutions providers in the industry. We have been delivering high quality services and world class technology solutions to customers since 2020. As a part of the software development industry, we are into 4 major verticals, viz - mobile app development, web development  along with providing  E-commerce development teams.
                                        </h3>
                                        <br />
                                        <h3 className="ServiceSliderPara ServiceSliderParaAbout">
                                            Our team of  experienced professionals comprises software developers, UI/UX designers, quality analysts, technology experts and the R&D team. The capabilities and knowledge they bring enables us to deliver complex projects on time.
                                        </h3>
                                        <br />
                                        <h3 className="ServiceSliderPara ServiceSliderParaAbout">
                                            What we bring to you is creativity, understanding, and expertise which ensures that the results delivered align with your vision and objectives.
                                        </h3>

                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="growBusiness my-5 Line3Parent">
                <div className="AboutServices3">
                    {/* <div className="Rounds"><img src={Rounds} alt="rounds" /></div>
                    <div className="Rounds2"><img src={Rounds2} alt="rounds2" /></div> */}
                </div>
                <Container>
                    <Row className="mt-4 d-flex justify-content-between">
                        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className="p-2">
                            <div className="AnalyticsTechnologies AnalyticsTechnologies1 px-4 py-4 wow animate__animated animate__fadeInLeft" style={{ animationDuration: '2s' }}>
                                <img src={linebtn} alt="linebtn" className="linebtn" />
                                <img src={linebtn2} alt="linebt2" className="linebtn2" />
                                <span className="MissionImg">
                                    <img src={Mission} alt="Mission" className="mb-" />
                                </span>
                                <div>
                                    <h2 className="my-3">
                                        Mission
                                    </h2>
                                    <p className="mb-0">
                                        • Understand and analyze clients requirements and their business.
                                    </p>
                                    <p className="mb-0">
                                        • Provide world-class standard professional services to the employees and customers.
                                    </p>
                                    <p className="mb-0">
                                        • Enhance clients business growth digitally.
                                    </p>
                                    <p className="mb-0">
                                        • Make product (Application / Game) which is useful for everyone worldwide.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className="p-2 wow animate__animated animate__fadeInRight" style={{ animationDuration: '2s' }}>
                            <Row className="my-3">
                                <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-end">
                                    {/* <Link to={"/RequestQuote"} style={{ textDecoration: "none" }}> */}
                                    <button variant="outline-success" className="AboutButton">
                                        <Link to={"/RequestQuote"} style={{ textDecoration: "none", color: "#fff" }}>
                                            Get a Quote
                                            <img src={ArrowWhite} className="ms-3 arrow" />
                                        </Link>
                                    </button>
                                    {/* </Link> */}
                                </Col>
                            </Row>
                            <Row className="">
                                <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                    <div className="reportTech reportTech1">
                                        <span className="MissionImg">
                                            <img src={eye} alt={eye} />
                                        </span>
                                        <h2 className="my-3">
                                            Vision
                                        </h2>
                                        <p>
                                            To become the leading IT Company who empowers people for their growth and happiness through the power of digital services.
                                        </p>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>

            <BeforeFooter />


            <Footer />
        </div>
        </>
    )
}
export default AboutSnepitech;
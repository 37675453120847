import { Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import ServicesWeb from './pages/ServicesWeb';
import ServicesApp from './pages/ServiceApp';
import ServiceSoftware from './pages/ServiceSoftWare';
import ServicesDesign from './pages/ServiceDesign';
import Technologies from './pages/Technologies';
import Portfolio from './pages/Portfolio';
import AboutSnepitech from './pages/AboutSnepitech';
import WhySnepitech from './pages/WhySnepitech';
import GrowthAtSnepitech from './pages/GrowthAtSnepitech';
import RequestQuote from './pages/RequestQuote';
import Hiring from './pages/Hiring';
import 'animate.css';
import WOW from 'wowjs';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/best-website-services-in-surat' element={<ServicesWeb />}></Route>
        <Route path='/best-application-services-in-surat' element={<ServicesApp />}></Route>
        <Route path='/best-software-services-in-surat' element={<ServiceSoftware />}></Route>
        <Route path='/best-designing-in-surat' element={<ServicesDesign />}></Route>
        <Route path='/best-company-for-technology' element={<Technologies />}></Route>
        <Route path='/Portfolio' element={<Portfolio />}></Route>
        <Route path='/AboutSnepitech' element={<AboutSnepitech />}></Route>
        <Route path='/best-services-for-website-and-applications' element={<WhySnepitech />}></Route>
        <Route path='/GrowthAtSnepitech' element={<GrowthAtSnepitech />}></Route>
        <Route path='/RequestQuote' element={<RequestQuote />}></Route>
        <Route path='/best-job-opportunities' element={<Hiring />}></Route>
      </Routes>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import './ServicesWeb.css';
import 'animate.css';
import WOW from 'wowjs';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

import { Col, Container, Row } from "react-bootstrap";

import ArrowWhite from "../Images/Home/ArrowWhite.png";
import s1 from "../Images/Services/1.png";
import s2 from "../Images/Services/2.png";
import s3 from "../Images/Services/3.png";
import s4 from "../Images/Services/4.png";
import s5 from "../Images/Services/5.png";
import s6 from "../Images/Services/6.png";
import s7 from "../Images/Services/7.png";
import s8 from "../Images/Services/8.png";
import ArrowBlack from "../Images/Home/ArrowBlack.png";

import FooterServiceIcon from "../Images/Services/FooterServiceIcon.png";

import Apple from "../Images/Services/Apple.png";
import setting from "../Images/Services/tabler_settings-pin.png";
import ep_service from "../Images/Services/ep_service.png";
import solar from "../Images/Services/solar_chat-square-code-bold-duotone.png";
import BeforeFooter from "../Component/BeforeFooter";
import { Link } from "react-router-dom";
import { PiMouseSimple } from "react-icons/pi";
import Seo from "../Component/Seo";

function ServiceSoftware() {

    const [scrollmouseClass, setScrollmouseClass] = useState("notShown");

    const handleScroll = () => {
        const currentScrollTop = window.scrollY;
        const showThreshold = 400; // Adjust this threshold as needed

        if (currentScrollTop > showThreshold) {
            setScrollmouseClass("Shown");
        } else {
            setScrollmouseClass("notShown");
        }
    };

    const scrollevent = () => {
        const currentScrollTop = window.scrollY;
        if (currentScrollTop > 400) {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const CustomNextArrow1 = ({ onClick }) => (
        <div className="custom-arrow next2" onClick={onClick}>
            <MdNavigateNext />
        </div>
    );

    const CustomPrevArrow1 = ({ onClick }) => (
        <div className="custom-arrow prev2" onClick={onClick}>
            <MdNavigateBefore />
        </div>
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [Pwidth, setPWidth] = useState("web")
    const [process1Settings, setProcess1Settings] = useState(
        {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <CustomNextArrow1 />,
            prevArrow: <CustomPrevArrow1 />,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        }
    )

    useEffect(() => {
        const updateSettings = () => {
            if (window.innerWidth < 992) {
                setPWidth("small");
                setProcess1Settings({
                    ...process1Settings,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                });
            } else {
                setPWidth("web");
                setProcess1Settings({
                    ...process1Settings,
                    slidesToShow: 3, // Adjust these values based on your design
                    slidesToScroll: 1,
                });
            }
        };

        // Initial setup
        updateSettings();

        // Update settings on window resize
        window.addEventListener("resize", updateSettings);

        // Cleanup
        return () => {
            window.removeEventListener("resize", updateSettings);
        };
    }, [process1Settings]);


    useEffect(() => {
        new WOW.WOW().init();
      }, []);

    return (
        <>
     <Seo
        title="Top Software Company | Web Development, Mobile Apps & UI/UX Design " 
        description="Leading software company specializing in web development, mobile app development, and UI/UX design. Innovative digital solutions to bring your ideas to life." 
        keywords="software , life ,Custom, technology ,specializing ,software ,development ,Companies, surat , varachha , mota"
      />

        <div onScroll={handleScroll}>
            <div className={`upperMouse ${scrollmouseClass}`}>
                <PiMouseSimple className="upperMouseIcon" onClick={scrollevent} />
            </div>
            <div className={`MainHome MainHome1`}>
                <Header />
                <Container>
                    <Row className="wow animate__animated animate__fadeInUp"
                        style={{ animationDuration: '2s' }}
                    >
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="mt-5 pt-5">
                            <h1 className="ServiceSliderHead">
                                SOFTWARE DEVELOPMENT
                                COMPANY
                            </h1>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="py-5">
                            <h3 className="ServiceSliderPara">
                                Our custom-made business software solutions provide complete services to a variety of organisations.
                            </h3>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <Link to={"/RequestQuote"} style={{ textDecoration: "none" }}>
                                <button variant="outline-success" className="AboutButton">
                                    Get a Quote
                                    <img src={ArrowWhite} alt="ArrowWhite" className="ms-3 arrow" />
                                </button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="Process Processmain">
                <Container>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <h1 className="ProcessHead wow animate__animated animate__fadeInLeft">Our Software Development Process</h1>
                        </Col>
                    </Row>
                    {
                        Pwidth == "web" ?
                            <>
                                <Row className="Process1 mt-5">
                                    <Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className="px-4">
                                        <div className="px-4 wow animate__animated animate__fadeInLeft">
                                            <h5 className="ProcessHeadText">
                                                Understanding
                                                Requirements
                                            </h5>
                                            <p className="ProcessParaText">
                                                This stage helps us understand
                                                clients perspective and end
                                                goals.
                                            </p>
                                            <img src={s1} alt="s1"/>
                                        </div>
                                    </Col>
                                    <Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className="px-4">
                                        <div className="px-4 wow animate__animated animate__fadeInLeft">
                                            <h5 className="ProcessHeadText">
                                                Using Right
                                                Technology
                                            </h5>
                                            <p className="ProcessParaText">
                                                This stage helps us understand
                                                clients perspective and end
                                                goals.
                                            </p>
                                            <img src={s3} alt="s3" />
                                        </div>
                                    </Col>
                                    <Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className="px-4">
                                        <div className="px-4 wow animate__animated animate__fadeInLeft">
                                            <h5 className="ProcessHeadText">
                                                Working On
                                                Design
                                            </h5>
                                            <p className="ProcessParaText">
                                                This stage helps us understand
                                                clients perspective and end
                                                goals.
                                            </p>
                                            <img src={s5} alt="s5" />
                                        </div>
                                    </Col><Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className="px-4">
                                        <div className="px-4 wow animate__animated animate__fadeInLeft">
                                            <h5 className="ProcessHeadText">
                                                Testing phase
                                            </h5>
                                            <p className="ProcessParaText">
                                                This stage helps us understand
                                                clients perspective and end
                                                goals.
                                            </p>
                                            <img src={s7} alt="s7" />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="Process2 mt-5">
                                    <Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className="px-4">
                                        <div className="px-4 wow animate__animated animate__fadeInRight">
                                            <img src={s2} alt="s2" />
                                            <h5 className="ProcessHeadText">
                                                Creating Strategies
                                            </h5>
                                            <p className="ProcessParaText">
                                                This stage helps us understand
                                                clients perspective and end
                                                goals.
                                            </p>

                                        </div>
                                    </Col>
                                    <Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className="px-4">
                                        <div className="px-4 wow animate__animated animate__fadeInRight">
                                            <img src={s4} alt="s4" />
                                            <h5 className="ProcessHeadText">
                                                Planning
                                            </h5>
                                            <p className="ProcessParaText">
                                                This stage helps us understand
                                                clients perspective and end
                                                goals.
                                            </p>

                                        </div>
                                    </Col>
                                    <Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className="px-4">
                                        <div className="px-4 wow animate__animated animate__fadeInRight">
                                            <img src={s6} alt="s6" />
                                            <h5 className="ProcessHeadText">
                                                Developing The
                                                App
                                            </h5>
                                            <p className="ProcessParaText">
                                                This stage helps us understand
                                                clients perspective and end
                                                goals.
                                            </p>

                                        </div>
                                    </Col>
                                    <Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className="px-4">
                                        <div className="px-4 wow animate__animated animate__fadeInRight">
                                            <img src={s8} alt="s8" />
                                            <h5 className="ProcessHeadText">
                                                Launching Project
                                            </h5>
                                            <p className="ProcessParaText">
                                                This stage helps us understand
                                                clients perspective and end
                                                goals.
                                            </p>

                                        </div>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row className="Processabc mt-5">

                                    <Slider {...process1Settings}>
                                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                            <div className="SmallWidth">
                                                <h5 className="ProcessHeadText">
                                                    Understanding
                                                    Requirements
                                                </h5>
                                                <p className="ProcessParaText">
                                                    This stage helps us understand
                                                    clients perspective and end
                                                    goals.
                                                </p>
                                                <div className="d-flex justify-content-center">
                                                    <img src={s1} alt="s1" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                            <div className="SmallWidth">
                                                <div className="d-flex justify-content-center">
                                                    <img src={s2} alt="s2" />
                                                </div>
                                                <h5 className="ProcessHeadText">
                                                    Creating Strategies
                                                </h5>
                                                <p className="ProcessParaText">
                                                    Various strategies are
                                                    developed to complete and
                                                    deliver the project on time.
                                                </p>

                                            </div>
                                        </Col>
                                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                            <div className=" SmallWidth">
                                                <h5 className="ProcessHeadText">
                                                    Using Right
                                                    Technology
                                                </h5>
                                                <p className="ProcessParaText">
                                                    This stage helps us understand
                                                    clients perspective and end
                                                    goals.
                                                </p>
                                                <div className="d-flex justify-content-center">
                                                    <img src={s3} alt="s3" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                            <div className="px-4 SmallWidth">
                                                <div className="d-flex justify-content-center">
                                                    <img src={s4} alt="s4" style={{ margin: "auto" }} />
                                                </div>
                                                <h5 className="ProcessHeadText">
                                                    Planning
                                                </h5>
                                                <p className="ProcessParaText">
                                                    This stage helps us understand
                                                    clients perspective and end
                                                    goals.
                                                </p>

                                            </div>
                                        </Col>
                                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                            <div className="px-4 SmallWidth">
                                                <h5 className="ProcessHeadText">
                                                    Working On
                                                    Design
                                                </h5>
                                                <p className="ProcessParaText">
                                                    This stage helps us understand
                                                    clients perspective and end
                                                    goals.
                                                </p>
                                                <div className="d-flex justify-content-center">
                                                    <img src={s5} alt="s5" style={{ margin: "auto" }} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                            <div className="px-4 SmallWidth">
                                                <div className="d-flex justify-content-center">
                                                    <img src={s6} alt="s6" style={{ margin: "auto" }} />
                                                </div>
                                                <h5 className="ProcessHeadText">
                                                    Developing The
                                                    Application or Web
                                                    App
                                                </h5>
                                                <p className="ProcessParaText">
                                                    This stage helps us understand
                                                    clients perspective and end
                                                    goals.
                                                </p>

                                            </div>
                                        </Col>
                                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                            <div className="px-4 SmallWidth">
                                                <h5 className="ProcessHeadText">
                                                    Testing phase
                                                </h5>
                                                <p className="ProcessParaText">
                                                    This stage helps us understand
                                                    clients perspective and end
                                                    goals.
                                                </p>
                                                <div className="d-flex justify-content-center">
                                                    <img src={s7} alt="s7" style={{ margin: "auto" }} />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                            <div className="px-4 SmallWidth">
                                                <div className="d-flex justify-content-center">
                                                    <img src={s8} alt="s8" style={{ margin: "auto" }} />
                                                </div>
                                                <h5 className="ProcessHeadText">
                                                    Launching Project
                                                </h5>
                                                <p className="ProcessParaText">
                                                    After successful testing, the
                                                    website is launched for the
                                                    audiences.
                                                </p>

                                            </div>
                                        </Col>
                                    </Slider>
                                </Row>
                            </>
                    }


                </Container>
            </div>

            <div className="Process mt-5">
                <Container>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <h1 className="ProcessHead wow animate__animated animate__fadeInUp">Technologies We Work With</h1>
                        </Col>
                    </Row>

                    <Row className="my-5">
                        <Col xxl="2" xl="2" lg="2" md="3" sm="4" xs="4" className="px-0 d-flex align-items-center justify-content-center">
                            <div className="Technologies wow animate__animated animate__fadeInUp">
                                <div>
                                    <img src={Apple} alt="Apple" />
                                    <p style={{ color: "#388087" }}>iOS Development</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl="2" xl="2" lg="2" md="3" sm="4" xs="4" className="px-0 d-flex align-items-center justify-content-center">
                            <div className="Technologies pt-2 wow animate__animated animate__fadeInDown">
                                <div>
                                    <img src={setting} alt="setting" />
                                    <p style={{ color: "#388087" }}>Application Integration</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl="2" xl="2" lg="2" md="3" sm="4" xs="4" className="px-0 d-flex align-items-center justify-content-center">
                            <div className="Technologies pt-2 wow animate__animated animate__fadeInUp">
                                <div>
                                    <img src={ep_service} alt="ep_service" />
                                    <p style={{ color: "#388087" }}>Maintenance and Support</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl="3" xl="4" lg="4" md="6" sm="6" xs="8" className="px-0 d-flex align-items-center justify-content-center">
                            <div className="Technologies Technologies1 p-2 ms-3 wow animate__animated animate__fadeInDown">
                                <div>
                                    <img src={solar} alt="solar" />
                                    <p style={{ color: "#388087" }}>Value-Driven Approach To Custom Software Development</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="mt-5 pt-5">
                <img src={FooterServiceIcon} alt="FooterServiceIcon" style={{ animationDuration: '2.5s' }} className="FooterServiceIcon wow animate__animated animate__bounceInDown" />
            </div>

            <BeforeFooter />

            <Footer />
        </div>
        </>
    )
}
export default ServiceSoftware;
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Component/Header";
import "animate.css";
import WOW from "wowjs";

import Rounds from "../Images/Home/Rounds.webp";
import Rounds2 from "../Images/Home/Round2.webp";
import CircleAnimation from "../Images/Home/CircleAnimation.png";
import MouseIcon from "../Images/Home/MouseIcon.png";
// import Linkedin from "../Images/Home/linkedin.png";
// import Twitter from "../Images/Home/twiter.png";

import Arrow2 from "../Images/Home/Arrow2.png";
import ArrowWhite from "../Images/Home/ArrowWhite.png";
import Line1 from "../Images/Home/Line1.png";
import Line3 from "../Images/Home/Line3.png";
// import ScrollElement from "../Images/Home/ScrollElement.svg";
// import Arrow from "../Images/Home/Arrow.svg";
import { FaInstagram } from "react-icons/fa";
import { MdWhatsapp } from "react-icons/md";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";

import ServiceIcon1 from "../Images/Home/ServicesIcon1.png";
import ServiceIcon2 from "../Images/Home/ServicesIcon2.png";
import ServiceIcon3 from "../Images/Home/ServicesIcon3.png";
import ServiceIcon4 from "../Images/Home/ServicesIcon4.png";

import Marquee1 from "../Images/Home/Marquee1.png";
import Marquee2 from "../Images/Home/Marquee2.png";
import Marquee3 from "../Images/Home/Marquee3.png";
import Marquee4 from "../Images/Home/Marquee4.png";
import Marquee5 from "../Images/Home/Marquee5.png";
import Marquee6 from "../Images/Home/Marquee6.png";

import AboutPeopleIcon from "../Images/Home/AboutUsPeopleIcon.png";
import fullStar from "../Images/Home/fullStar.png";
import halfStar from "../Images/Home/halfStar.png";
import ArrowBlack from "../Images/Home/ArrowBlack.png";

import mail from "../Images/Home/mail.png";
import phone from "../Images/Home/Phone.png";
import snepitech from "../Images/Footer/skypeWhite.png";



import "./Home.css";
import Footer from "../Component/Footer";
import { Link } from "react-router-dom";

import { PiMouseSimple } from "react-icons/pi";
import Seo from "../Component/Seo";



function Home() {
  const [showFullReview, setShowFullReview] = useState(false);

  const handleToggleClick = (index) => {
    setShowFullReview((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const Production = [
    Marquee1,
    Marquee2,
    Marquee3,
    Marquee4,
    Marquee5,
    Marquee6,
  ];

  const AboutPeopleSay = [
    {
      img: AboutPeopleIcon,
      name: "Vikas Kothiya",
      designation: "CEO of Kothiya Infotech",
      rate: [fullStar, fullStar, fullStar, fullStar, halfStar],
      reviewMsg: `"We have always been able to count with them, the quality of the deliverables were wonderful and their attitude is always positive." `,
    },
    {
      img: AboutPeopleIcon,
      name: "Prashanth Murdeshawar",
      designation: "CEO of vetware Infotech",
      rate: [fullStar, fullStar, fullStar, fullStar, halfStar],
      reviewMsg: `"We've been working with Snepitech for almost two years and this team is awesome! They always respond fast, delivering very complex tasks in very short periods of time. We're very happy to work with this team and we'll continue to work together in the future." `,
    },
    {
      img: AboutPeopleIcon,
      name: "Yash Patel",
      designation: "CEO of Cattle Infotech",
      rate: [fullStar, fullStar, fullStar, fullStar, fullStar],
      reviewMsg: `"Our satisfaction with Snepitech is beyond measure, and we eagerly look forward to continuing and expanding our collaboration with them in the future. Their coding expertise has been the backbone of our successful project deliveries" `,
    },
    {
      img: AboutPeopleIcon,
      name: "Rikesh Shah",
      designation: "Fonder of Ashwin Diamond ",
      rate: [fullStar, fullStar, fullStar, fullStar, halfStar],
      reviewMsg: `"Their coding capabilities are truly top-notch, delivering flawless solutions for complex tasks with remarkable speed. Working with Snepitech has not only met but exceeded our expectations." `,
    },
    {
      img: AboutPeopleIcon,
      name: "Pinal Patel",
      designation: "Owner of Shivani Gems ",
      rate: [fullStar, fullStar, fullStar, fullStar, halfStar],
      reviewMsg: `"Snepitech has proven to be a valuable asset, and we have full confidence in their ability to contribute to our ongoing and upcoming endeavors. If you are seeking a responsive, skilled, and reliable team for your technical projects, Snepitech comes highly recommended." `,
    },
    {
      img: AboutPeopleIcon,
      name: "Jaya Pandey",
      designation: "Freelancer",
      rate: [fullStar, fullStar, fullStar, fullStar, fullStar],
      reviewMsg: `"We are extremely grateful to Snepitech for developing an outstanding mobile app for GDM tracking in pregnancy. Their attention to detail, technical proficiency, and commitment to delivering on time and within budget were evident throughout the project. The app has been well-received by our clients, providing them with a valuable tool for monitoring their health and communicating with their healthcare providers." `,
    },
  ];

  const [scrollTop, setScrollTop] = useState(0);

  const [scrollClass, setScrollClass] = useState("Line1Scroll");
  const [scrolldirection, setScrollDirection] = useState("down");

  const [scrollmouseClass, setScrollmouseClass] = useState("notShown");

  const scrollevent = () => {
    const currentScrollTop = window.scrollY;
    if (currentScrollTop > 400) {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollTop = window.scrollY;

    const showThreshold = 400; // Adjust this threshold as needed

    if (currentScrollTop > showThreshold) {
      setScrollmouseClass("Shown");
    } else {
      setScrollmouseClass("notShown");
    }

    if (currentScrollTop > scrollTop) {
      // Scrolling down
      console.log("Scrolling down");
      setScrollDirection("down");
    } else {
      // Scrolling up
      console.log("Scrolling up");
      setScrollDirection("up");
    }

    const scrollDiff = Math.abs(currentScrollTop - scrollTop);

    // Calculate the remaining duration for reverse animation
    const remainingDuration = Math.min(8000 - scrollDiff * 2, 8000);

    // Set the animation duration dynamically
    document.documentElement.style.setProperty(
      "--animation-duration",
      `${remainingDuration}ms`
    );

    if (
      currentScrollTop > 400 &&
      currentScrollTop < 1450 &&
      scrolldirection === "down"
    ) {
      setScrollClass("Line1Scroll");
    } else if (
      currentScrollTop > 1200 &&
      currentScrollTop < 1800 &&
      scrolldirection === "down"
    ) {
      setScrollClass("Line2Scroll");
    } else if (
      currentScrollTop > 400 &&
      currentScrollTop < 1200 &&
      scrolldirection === "up"
    ) {
      setScrollClass("Line1ScrollU");
    } else if (
      currentScrollTop > 1400 &&
      currentScrollTop < 2000 &&
      scrolldirection === "up"
    ) {
      setScrollClass("Line2ScrollU");
    }

    setScrollTop(currentScrollTop);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollsecond = () => {
    window.scrollTo(0, 950);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollTop]);

 
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <>
    <Seo
        title="IT Solutions & Business Services Company " 
        description="IT Solutions & Business Services Company We Build innovative web and mobile apps. " 
        keywords="technology-consulting , Services , web , innovative, snep , snap ,snepitech , operate , technology consulting "
      />

    <div onScroll={handleScroll}>
      <div className={`upperMouse ${scrollmouseClass}`}>
        <PiMouseSimple className="upperMouseIcon" onClick={scrollevent} />
      </div>

      <Header />
      <div className="MainHome">
        <Container fluid>
          <Row>
            <Col
              xxl="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              className="HomeWrapper"
            >
              <div className="HomeBack">
                <div className="Rounds">
                  <img src={Rounds} alt="rounds" />
                </div>
                <div className="Rounds2">
                  <img src={Rounds2} alt="rounds2" />
                </div>
              </div>
              <div
                className="HomeContents mt-5 pt-5 wow animate__animated animate__fadeInDown"
                style={{ animationDuration: "2s" }}
              >
                <div>
                  <div className="socialMediaIcons mb-5">
                    <a
                      href="https://www.instagram.com/accounts/login/?next=/snepitech/"
                      target="_blank"
                       rel="noreferrer"
                    >
                      {/* <img src={Instagram} alt="instagram" className="sliderScocialMedia" /> */}
                      <FaInstagram
                        style={{ color: "#378187", fontSize: "22px" }}
                      />
                    </a>
                    <a
                      href="https://wa.me/919426114378?text=Hello,%20Priyank%20Gadhiya%20"
                      target="_blank"
                       rel="noreferrer"
                    >
                      {/* <img src={Whatsapp} alt="whatsapp" className="sliderScocialMedia" /> */}
                      <MdWhatsapp
                        style={{ color: "#378187", fontSize: "22px" }}
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/snepitech/mycompany/?viewAsMember=true"
                      target="_blank"
                       rel="noreferrer"
                    >
                      {/* <img src={Linkedin} alt="linkedin" className="sliderScocialMedia" /> */}
                      <FaLinkedinIn
                        style={{ color: "#378187", fontSize: "22px" }}
                      />
                    </a>
                    <a href="https://twitter.com/snepitech" target="_blank"  rel="noreferrer">
                      {/* <img src={Twitter} alt="twitter" className="sliderScocialMedia" /> */}
                      <FaXTwitter
                        style={{ color: "#378187", fontSize: "22px" }}
                      />
                    </a>
                  </div>
                  <h1 className="HomeContentsHead">
                    IT Solutions & Business
                    <br />
                    Services Company
                  </h1>
                  <h1 className="HomeContentHeadSub mt-4">
                    We Build innovative web and mobile apps
                  </h1>
                  <div className="CircleAnimation mt-5" onClick={scrollsecond}>
                    <img src={CircleAnimation} alt="circle animation" />
                    <img src={MouseIcon} alt="mouse icon" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="AboutHome LineRelative">
        <Container fluid>
          <Row>
            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <div className="AboutHome1">
                <div className="Rounds">
                  <img src={Rounds} alt="rounds" />
                </div>
                <div className="Rounds2">
                  <img src={Rounds2} alt="rounds2" />
                </div>
              </div>
              <Container>
                <Row>
                  <Col
                    xxl="9"
                    xl="9"
                    lg="9"
                    md="9"
                    sm="12"
                    xs="12"
                    className="wow animate__animated animate__fadeInLeft"
                    style={{ animationDuration: "2s" }}
                  >
                    <h1 className="AboutContentsHead">
                      Developing Software<br></br>
                      Solutions For Diverse Verticals.
                    </h1>
                    <p className="AboutContentsParaGraph">
                      We helps businesses to renew & improve existing conditions
                      so that their business can achieve higher efficiencies and
                      stay relevant according to current times. SnepiTech is
                      Your digital partner to create technical design .We are a
                      global creative agency that combines design expertise with
                      technology and intelligence to revolutionize your
                      business.
                    </p>
                  </Col>
                  <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12">
                    <Link
                      to={"/aboutSnepitech"}
                      style={{ textDecoration: "none" }}
                    >
                      <button variant="outline-success" className="AboutButton">
                        About Us
                        <img src={Arrow2} alt="Arrow2" className="ms-3 arrow" />
                      </button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <div className={`Line ${scrollClass}`}>
          <Container fluid>
            <Row>
              <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <img src={Line1} alt="Line 1" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="AboutHome">
        <Container fluid>
          <Row>
            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <div className="AboutHome1">
                <div className="Rounds">
                  <img src={Rounds} alt="rounds" />
                </div>
                <div className="Rounds2">
                  <img src={Rounds2} alt="rounds2" />
                </div>
              </div>
              <Container>
                <Row>
                  <Col
                    xxl="9"
                    xl="9"
                    lg="9"
                    md="9"
                    sm="12"
                    xs="12"
                    className="wow animate__animated animate__fadeInUp"
                    style={{ animationDuration: "1s" }}
                  >
                    <h1 className="AboutContentsHead">Our Services</h1>
                    <p className="AboutContentsParaGraph ServicesContentsParaGraph">
                      As one of the leading software development companies, we
                      at Vasundhara have a diverse range of services for our
                      clients, where we operate in these core areas services.
                    </p>
                  </Col>
                  <Col
                    xxl="3"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="12"
                    xs="12"
                    className="d-flex align-items-center justify-content-end small"
                  >
                    <Link
                      to={"/best-website-services-in-surat"}
                      style={{ textDecoration: "none" }}
                    >
                      <button variant="outline-success" className="AboutButton">
                        Our Services
                        <img
                          src={ArrowWhite}
                          alt="ArrowWhite"
                          className="ms-3 arrow"
                        />
                      </button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={`mt-5 Line3Parent`}>
        <Container fluid>
          <Row>
            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <div className="AboutServices3">
                <div className="Rounds">
                  <img src={Rounds} alt="rounds" />
                </div>
                <div className="Rounds2">
                  <img src={Rounds2} alt="rounds2" />
                </div>
              </div>
              <Container>
                <Row className="mainBoxParent">
                  <Col
                    xxl="7"
                    xl="8"
                    lg="8"
                    md="6"
                    sm="12"
                    xs="12"
                    className="vh50"
                  >
                    <div
                      className="BoxHomeServices BoxHomeServices1 wow animate__animated animate__fadeInLeft"
                      style={{ animationDuration: "2s" }}
                    >
                      <div className="ServicesIcon d-flex justify-content-left">
                        <img src={ServiceIcon1} alt="ServiceIcon1" />
                      </div>
                      <div className="HeadServices d-flex justify-content-left mt-4">
                        <h2>Website Dev</h2>
                      </div>
                      <div className="ParaServices mt-4">
                        <p>
                          We help companies with unique and comprehensive web
                          development services that empower you and your
                          clients.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xxl="5"
                    xl="4"
                    lg="4"
                    md="6"
                    sm="12"
                    xs="12"
                    className="vh50"
                  >
                    <div
                      className="BoxHomeServices BoxHomeServices2 wow animate__animated animate__fadeInRight"
                      style={{ animationDuration: "2s" }}
                    >
                      <div className="BoxHomeServices BoxHomeServices2">
                        <div className="ServicesIcon d-flex justify-content-left">
                          <img src={ServiceIcon2} alt="ServiceIcon2" />
                        </div>
                        <div className="HeadServices1 d-flex justify-content-left mt-4">
                          <h2>UI/UX & Graphic Design</h2>
                        </div>
                        <div className="ParaServices1 d-flex justify-content-left mt-4">
                          <p>
                            We blend our creative ideas and utilize the best
                            tools and techniques in the field.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xxl="5"
                    xl="3"
                    lg="4"
                    md="6"
                    sm="12"
                    xs="12"
                    className="vh50"
                  >
                    <div
                      className="BoxHomeServices BoxHomeServices3 wow animate__animated animate__fadeInLeft"
                      style={{ animationDuration: "2s" }}
                    >
                      <div className="ServicesIcon d-flex justify-content-left">
                        <img src={ServiceIcon3} alt="ServiceIcon3" />
                      </div>
                      <div className="HeadServices2 d-flex justify-content-left mt-4">
                        <h2>Software Development</h2>
                      </div>
                      <div className="ParaServices2 d-flex justify-content-left mt-4">
                        <p>
                          A great way to tap into the fastest growing operating
                          system of Computer!
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xxl="7"
                    xl="9"
                    lg="8"
                    md="6"
                    sm="12"
                    xs="12"
                    className="vh50"
                  >
                    <div
                      className="BoxHomeServices BoxHomeServices4 wow animate__animated animate__fadeInRight"
                      style={{ animationDuration: "2s" }}
                    >
                      <div className="ServicesIcon d-flex justify-content-left">
                        <img src={ServiceIcon4} alt="ServiceIcon4" />
                      </div>
                      <div className="HeadServices d-flex justify-content-left mt-4">
                        <h2>Mobile App Development</h2>
                      </div>
                      <div className="ParaServices d-flex justify-content-left mt-4">
                        <p>
                          We can assist you with user-friendly, interactive and
                          feature-rich mobile app development.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <div className={`Line3`}>
          <Container fluid>
            <Row>
              <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <img src={Line3} alt={Line3} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div
        className="Production my-5 wow animate__animated animate__fadeInUp"
        style={{ animationDuration: "2s" }}
      >
        <div className="ParentLine">
          <div
            className="ChildLine d-flex justify-content-evenly align-items-center"
            style={{ overflow: "hidden" }}
          >
            <marquee scrollamount="15">
              {[...Production, ...Production].map((i, index) => (
                <img
                  key={index}
                  src={i}
                  className="ProductionImg mx-5 px-4"
                  alt={`Production ${index}`}
                />
              ))}
            </marquee>
          </div>
        </div>
      </div>

      <div className="AboutHome AboutUs AboutmarginTop">
        <Container fluid>
          <Row>
            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
    
              <Container>
                <Row>
                  <Col
                    xxl="9"
                    xl="9"
                    lg="9"
                    md="9"
                    sm="12"
                    xs="12"
                    className="wow animate__animated animate__fadeInUp"
                    style={{ animationDuration: "2s" }}
                  >
                    <h1 className="AboutContentsHead AboutUsContentsHead">
                      What they say about us
                    </h1>
                    <p className="AboutContentsParaGraph ServicesContentsParaGraph">
                      S We provide customized pricing for each project and
                      request we receive. Fill the form below and receive a
                      personalized quote for your requirements.
                    </p>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row className="AboutUsBoxPart mt-5 pt-5">
                  {AboutPeopleSay.map((i, index) => {
                    let animationClass = "";
                    if (index === 0 && index === 3) {
                      animationClass = "animate__fadeInLeft";
                    } else if (index === 2 && index === 5) {
                      animationClass = "animate__fadeInRight";
                    } else {
                      animationClass = "animate__fadeInUp";
                    }

                    return (
                      <Col
                        xxl="4"
                        xl="4"
                        lg="4"
                        md="6"
                        sm="12"
                        xs="12"
                        className="p-3 d-flex align-items-center justify-content-center"
                        key={index}
                      >
                        <div
                          className={`AboutUsBox pt-5 wow animate__animated ${animationClass} ${
                            index % 3 === 1 ? "CustomStyle" : ""
                          }`}
                          style={{ animationDuration: "2s" }}
                        >
                          <img src={i.img} alt={i.img} width={"70px"} />
                          <h5
                            className="mt-3 mb-0"
                            style={{ color: "#F6F6F2" }}
                          >
                            {i.name}
                          </h5>
                          <p
                            style={{
                              color: "#F6F6F2",
                              fontWeight: "300",
                              fontSize: "14px",
                            }}
                            className="mb-0"
                          >
                            {i.designation}
                          </p>
                          {i?.rate?.map((r, idx) => {
                            return <img key={idx} src={r} alt={idx} />;
                          })}
                          <div
                            style={{ position: "relative", minHeight: "90px" }}
                            className="reviewscroll"
                          >
                            <p
                              style={{
                                color: "#F6F6F2",
                                fontWeight: "300",
                                width: "270px",
                                overflow: "hidden",
                                maxHeight: "150px",
                              }}
                              className={`mb-0 mt-4 d-flex justify-content-center m-auto ${
                                !showFullReview[index] &&
                                i.reviewMsg.length > 140
                                  ? ""
                                  : "reviewMsg"
                              }`}
                            >
                              {showFullReview[index]
                                ? i.reviewMsg
                                : i.reviewMsg.slice(0, 140)}
                            </p>
                            {i.reviewMsg.length > 140 && (
                              <a 
                                onClick={() => handleToggleClick(index)}
                                style={{ cursor: "pointer", color: "#c9c9c1" }}
                              >
                                <u>
                                  {showFullReview[index]
                                    ? "Less..."
                                    : "More..."}
                                </u>
                              </a>
                            )}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="AboutFooterWebsite mt-5">
        <Container fluid>
          <Row>
            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <Container className="py-5">
                <Row className="mb-2">
                  <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                    <h1 className="d-flex justify-content-start beforeFooterHead wow animate__animated animate__fadeInLeft">
                      Looking for career opportunity ?
                    </h1>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="12"
                    xs="6"
                    className=" wow animate__animated animate__fadeInUp col-12"
                  >
                    <Link to={"mailto:info@snepitech.com"} target="_blank">
                      <div
                        className="contactFooter d-flex"
                        style={{ alignItems: "center" }}
                      >
                        <img
                          style={{ height: "15px", objectFit: "cover" }}
                          src={mail}
                          alt={mail}
                        />
                        <p
                          style={{ color: "#F6F6F2", fontWeight: "300" }}
                          className="mb-0 pb-0 pt-0 ps-3"
                        >
                          info@snepitech.com
                        </p>
                      </div>
                    </Link>
                    <Link to={"tel:+919426114378"} target="_blank">
                      <div
                        className="contactFooter d-flex mt-2"
                        style={{ alignItems: "center" }}
                      >
                        <img
                          style={{ height: "17px", objectFit: "cover" }}
                          src={phone}
                          alt={phone}
                        />
                        <p
                          style={{ color: "#F6F6F2", fontWeight: "300" , textAlign:"start"}}
                          className="mb-0 pb-0 ps-3 col-12"
                        >
                          +91 94261-14378
                        </p>
                      </div>
                    </Link>
                    <Link
                      to={"https://secure.skype.com/portal/overview"}
                      target="_blank"
                    >
                      <div
                        className="contactFooter d-flex mt-2"
                        style={{ alignItems: "center" }}
                      >
                        <img
                          style={{ height: "17px", objectFit: "cover" }}
                          src={snepitech}
                          alt={snepitech}
                        />
                        <p
                          style={{ color: "#F6F6F2", fontWeight: "300" }}
                          className="mb-0 pb-0 ps-3"
                        >
                          snepitech
                        </p>
                      </div>
                    </Link>
                  </Col>
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    xs="6"
                    className="d-flex justify-content-end"
                  >
                    <Link
                      to={"/AboutSnepitech"}
                      className="ServiceButtonFooter"
                    >
                      <button
                        variant="outline-success"
                        className="AboutButtonFooter"
                      >
                        About Us
                        <img
                          src={ArrowBlack}
                          className="ms-3 arrowback"
                          alt={ArrowBlack}
                        />
                      </button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </div>
    </>
  );
}

export default Home;

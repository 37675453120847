import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import { Col, Container, Row } from "react-bootstrap";
import "./Technologies.css";
import 'animate.css';
import WOW from 'wowjs';

import ArrowBlack from "../Images/Home/ArrowBlack.png";
import ArrowWhite from "../Images/Home/ArrowWhite.png";

import Rounds from "../Images/Home/Rounds.webp";
import Rounds2 from "../Images/Home/Round2.webp";

import ServiceIcon1 from "../Images/Home/ServicesIcon1.png";
import ServiceIcon2 from "../Images/Home/ServicesIcon2.png";
import ServiceIcon3 from "../Images/Home/ServicesIcon3.png";
import ServiceIcon4 from "../Images/Home/ServicesIcon4.png";

import ColumnChart from "../Images/Technologies/ColumnChart.png";
import Document from "../Images/Technologies/Document.png";
import Users from "../Images/Technologies/Users.png";
import Testing from "../Images/Technologies/testing.png";
import LineLight from "../Images/Technologies/LineLight.png";

import BeforeFooter from "../Component/BeforeFooter";
import { Link } from "react-router-dom";
import { PiMouseSimple } from "react-icons/pi";
import { LocalSee } from "@mui/icons-material";
import Seo from "../Component/Seo";


function Technologies() {

    const [scrollmouseClass, setScrollmouseClass] = useState("notShown");


    const handleScroll = () => {
        const currentScrollTop = window.scrollY;
        const showThreshold = 400; // Adjust this threshold as needed

        if (currentScrollTop > showThreshold) {
            setScrollmouseClass("Shown");
        } else {
            setScrollmouseClass("notShown");
        }
    };

    const scrollevent = () => {
        const currentScrollTop = window.scrollY;
        if (currentScrollTop > 400) {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
   
    useEffect(() => {
        new WOW.WOW().init();
    }, []);

    return (
        <>
        <Seo
        title="Top Software Company | Web Development, Mobile Apps, UI/UX Services " 
        description="Leading software company offering web development, mobile app development, and UI/UX services. Transform your digital presence with cutting-edge technologies. " 
        keywords="TECHNOLOGIES , software , tech consulting ,development , services "
      />
        <div onScroll={handleScroll}>
            <div className={`upperMouse ${scrollmouseClass}`}>
                <PiMouseSimple className="upperMouseIcon" onClick={scrollevent} />
            </div>
            <div className={`MainHome MainHome1`}>
                <Header />
                <Container>
                    <Row className="wow animate__animated animate__fadeInUp"
                        style={{ animationDuration: '2s' }}>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="mt-5 pt-5">
                            <h1 className="ServiceSliderHead">
                                TECHNOLOGIES<br></br>
                                WE DEPLOY
                            </h1>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="py-5">
                            <h3 className="ServiceSliderPara">
                                Our cutting-edge technologies help in delivering our customers with software solutions that are seamless, efficient, and streamlines with their vision.
                            </h3>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <Link to={"/RequestQuote"} style={{ textDecoration: "none" }}>
                                <button variant="outline-success" className="AboutButton">
                                    Get a Quote
                                    <img src={ArrowWhite} alt="ArrowWhite" className="ms-3 arrow" />
                                </button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="mt-5 Line3Parent">
                <Container fluid>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div className="AboutServices3">
                                <div className="Rounds"><img src={Rounds} alt="rounds" /></div>
                                <div className="Rounds2"><img src={Rounds2} alt="rounds2" /></div>
                            </div>
                            <Container>
                                <Row>
                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                        <h1 className="ProcessHead1 wow animate__animated animate__fadeInLeft">Our Services</h1>
                                    </Col>
                                </Row>
                                <Row className="mainBoxParent mt-4">
                                    <Col xxl="7" xl="8" lg="8" md="6" sm="12" xs="12" className="vh50">
                                        <div className="BoxHomeServices BoxHomeServicess BoxHomeServices1 wow animate__animated animate__fadeInLeft" style={{ animationDuration: '2s' }}>
                                            <div className="ServicesIcon d-flex justify-content-left">
                                                <img src={ServiceIcon1} alt="ServiceIcon1" />
                                            </div>
                                            <div className="HeadServices d-flex justify-content-left mt-4">
                                                <h2>Website Dev</h2>
                                            </div>
                                            <div className="ParaServices mt-4">
                                                <p>We help companies with unique and comprehensive web development services that empower you and your clients.</p>
                                            </div>
                                            <Link to={"/best-website-services-in-surat"} style={{ textDecoration: "none" }}>
                                                <button variant="outline-success" className="AboutButton ServicesBtnTech">
                                                    Explore
                                                    <img src={ArrowWhite} alt="ArrowWhite" className="ms-3 arrow smallButtonArrow" />
                                                </button>
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col xxl="5" xl="4" lg="4" md="6" sm="12" xs="12" className="vh50">
                                        <div className="BoxHomeServices BoxHomeServicess BoxHomeServices2 wow animate__animated animate__fadeInRight" style={{ animationDuration: '2s' }}>
                                            <div className="BoxHomeServices BoxHomeServices2">
                                                <div className="ServicesIcon d-flex justify-content-left">
                                                    <img src={ServiceIcon2} alt="ServiceIcon2" />
                                                </div>
                                                <div className="HeadServices1 d-flex justify-content-left mt-4">
                                                    <h2>UI/UX & Graphic Design</h2>
                                                </div>
                                                <div className="ParaServices1 d-flex justify-content-left mt-4">
                                                    <p>We blend our creative ideas and utilize the best tools and techniques in the field.</p>
                                                </div>
                                                <Link to={"/best-designing-in-surat"} style={{ textDecoration: "none" }}>
                                                    <button variant="outline-success" className="AboutButton ServicesBtnTech">
                                                        Explore
                                                        <img src={ArrowWhite} alt="ArrowWhite" className="ms-3 arrow smallButtonArrow" />
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl="5" xl="4" lg="4" md="6" sm="12" xs="12" className="vh50">
                                        <div className="BoxHomeServices BoxHomeServicess BoxHomeServices3 wow animate__animated animate__fadeInLeft" style={{ animationDuration: '2s' }}>
                                            <div className="ServicesIcon d-flex justify-content-left">
                                                <img src={ServiceIcon3} alt="ServiceIcon3" />
                                            </div>
                                            <div className="HeadServices2 d-flex justify-content-left mt-4">
                                                <h2>Software Development</h2>
                                            </div>
                                            <div className="ParaServices2 d-flex justify-content-left mt-4">
                                                <p>A great way to tap into the fastest growing operating system of Computer!</p>
                                            </div>
                                            <Link to={"/best-software-services-in-surat"} style={{ textDecoration: "none" }}>
                                                <button variant="outline-success" className="AboutButton ServicesBtnTech">
                                                    Explore
                                                    <img src={ArrowWhite} alt="ArrowWhite" className="ms-3 arrow smallButtonArrow" />
                                                </button>
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col xxl="7" xl="8" lg="8" md="6" sm="12" xs="12" className="vh50">
                                        <div className="BoxHomeServices BoxHomeServicess BoxHomeServices4 wow animate__animated animate__fadeInRight" style={{ animationDuration: '2s' }}>
                                            <div className="ServicesIcon d-flex justify-content-left">
                                                <img src={ServiceIcon4} alt="ServiceIcon4" />
                                            </div>
                                            <div className="HeadServices d-flex justify-content-left mt-4">
                                                <h2>Mobile App Development</h2>
                                            </div>
                                            <div className="ParaServices d-flex justify-content-left mt-4">
                                                <p>We can assist you with user-friendly, interactive and feature-rich mobile app development.</p>
                                            </div>
                                            <Link to={"/best-application-services-in-surat"} style={{ textDecoration: "none" }}>
                                                <button variant="outline-success" className="AboutButton ServicesBtnTech">
                                                    Explore
                                                    <img src={ArrowWhite} alt="ArrowWhite" className="ms-3 arrow smallButtonArrow" />
                                                </button>
                                            </Link>
                                        </div>
                                    </Col>

                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="growBusiness my-5 Line3Parent">
                <div className="AboutServices3">
                    {/* <div className="Rounds"><img src={Rounds} alt="rounds" /></div>
                    <div className="Rounds2"><img src={Rounds2} alt="rounds2" /></div> */}
                </div>
                <Container>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <h1 className="ProcessHead1 wow animate__animated animate__fadeInUp" style={{ animationDuration: '2s' }}>
                                Best Services Grow Your Business Value
                            </h1>
                        </Col>
                    </Row>

                    <Row className="mt-4 d-flex justify-content-between">
                        <Col xxl="5" xl="5" lg="5" md="12" sm="12" xs="12" className="p-2">
                            <div className="AnalyticsTechnologies px-4 py-5 wow animate__animated animate__fadeInLeft" style={{ animationDuration: '2s' }}>
                                <img src={ColumnChart} alt="ColumnChart" className="mb-3" />
                                <div>
                                    <h1>
                                        Advanced analytics
                                    </h1>
                                    <p>
                                        Synergistically pursue accurate initiatives
                                        without economically sound imperatives.
                                    </p>
                                    <p>
                                        Professionally architect unique process
                                        improvements via optimal.
                                    </p>
                                </div>

                                <div>
                                    <h1>
                                        Included with...
                                    </h1>
                                    <p className="mb-0">
                                        ⨀   High-converting
                                    </p>
                                    <p className="mb-0">
                                        ⨀   Personal branding
                                    </p>
                                    <p className="mb-0">
                                        ⨀   Modernized prospecting
                                    </p>
                                    <p className="mb-0">
                                        ⨀   Clean and modern
                                    </p>
                                    <p className="mb-0">
                                        ⨀   Showcasing success
                                    </p>
                                    <p className="mb-0">
                                        ⨀   Bootstrap latest version
                                    </p>

                                </div>
                            </div>
                        </Col>
                        <Col xxl="7" xl="7" lg="7" md="12" sm="12" xs="12" className="p-2 d-flex align-items-xl-end align-items-lg-start justifycontent-end">
                            <Row>
                                <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="12" className="pt-2 mt-2">
                                    <div className="reportTech wow animate__animated animate__fadeInDown" style={{ animationDuration: '2s' }}>
                                        <img src={Document} alt="Document" />
                                        <h3 className="my-3">
                                            Automated Reports
                                        </h3>
                                        <p>
                                            Synergistically pursue accurate
                                            initiatives without economically
                                            sound imperatives.
                                        </p>
                                    </div>
                                </Col>
                                <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="12" className="pt-2 mt-2">
                                    <div className="reportTech wow animate__animated animate__fadeInDown" style={{ animationDuration: '2s' }}>
                                        <img src={Users} alt="Users" />
                                        <h3 className="my-3">
                                            User Journey Flow
                                        </h3>
                                        <p>
                                            Synergistically pursue accurate
                                            initiatives without economically
                                            sound imperatives.
                                        </p>
                                    </div>
                                </Col>
                                <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="12" className="pt-2 mt-2">
                                    <div className="reportTech wow animate__animated animate__fadeInUp" style={{ animationDuration: '2s' }}>
                                        <img src={Testing} alt="Testing" />
                                        <h3 className="my-3">
                                            Compare A/B Testing
                                        </h3>
                                        <p>
                                            Credibly disintermediate
                                            functional processes for team
                                            driven action.
                                        </p>
                                    </div>
                                </Col>
                                <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="12" className="pt-2 mt-2">
                                    <div className="reportTech wow animate__animated animate__fadeInUp" style={{ animationDuration: '2s' }}>
                                        <img src={LineLight} alt="LineLight" />
                                        <h3 className="my-3">
                                            Easy Customization
                                        </h3>
                                        <p>
                                            Dynamically develop ubiquitous
                                            opportunities whereas
                                            relationships.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            <BeforeFooter />

            <Footer />
        </div>
        </>
    )
}
export default Technologies ;
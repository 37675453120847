import React, { useEffect } from "react";
import Header from "../Component/Header";
import { Col, Container, Row } from "react-bootstrap";
import 'animate.css';
import WOW from 'wowjs';
import "./Hiring.css";
import Footer from "../Component/Footer";
import BeforeFooterHire from "../Component/BeforeFooterHire";
import FooterServiceIcon from "../Images/Footer/FooterServiceIcon1.png";

import vector1 from "../Images/Hiring/Vector 1.png";
import vector2 from "../Images/Hiring/Vector 2.png";
import HiringBG from "../Images/Hiring/HiringBG.png";
import star from "../Images/Hiring/Star.png";
import quate from "../Images/Hiring/quote_up.png";
import Seo from "../Component/Seo";

function Hiring() {

    const handleMouseOver = () => {
        const elements = document.getElementsByClassName("Hiring");
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.opacity = 0;
            elements[i].style.transform = "translateY(300px)";
            elements[i].style.fontSize = "20px";
        }
    };


    const handleMouseOut = () => {
        const elements = document.getElementsByClassName("Hiring");
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.opacity = 1;
            elements[i].style.transform = "translateY(150px)";
            elements[i].style.fontSize = "";
        }
    };


    useEffect(() => {
        new WOW.WOW().init();
      }, []);
     
    return (
        <>
        <Seo
        title="Why Work With Us? " 
        description="Boost your career with Snepitech. Join our innovative team, thrive in a collaborative environment, and grow with exciting opportunities. Apply now! " 
        keywords="career , innovative , team ,  environment,  grow , Surat , Why Work With Us ,Techna"
      />
        <div className="mainHire">
            <Header />

            <Container className="abc123">
                <Row className="main">
                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                        <Row>
                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                <div className="Hiring wow animate__animated animate__fadeInUp" style={{ animationDuration: '1s' }}>
                                    <button className="hellobtn">Hello !</button>
                                    <div className="nameDesignation">
                                        <h1>I'm <font color="#6FB3B8">Techna</font></h1>
                                        <h1>App Developer</h1>
                                    </div>
                                    <div className="img1">
                                        <img src={vector1} title="SnepiTech" alt="vector1" className="img11" />
                                    </div>
                                    <div className="img2">
                                        <img src={vector2} title="SnepiTech" alt="vector2" className="img22" />
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Col xs="12">
                        <div className="EmpIconRelative">
                            <div className="empIcon mt-5" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
                                <div className="quatepart">
                                    <img src={quate} className="imgleft quate aroundimg wow animate__animated animate__fadeInLeft" alt="Quotation" />
                                    <h5 className="imgleft qut-content aroundimg wow animate__animated animate__fadeInLeft">Techna’s Exceptional App Development ensures our App’s success. Highly Recommended</h5>
                                </div>
                                <img src={FooterServiceIcon} title="Techna" alt="Footer Service Icon" style={{ animationDuration: '1.5s' }} className="FooterServiceIcon1 wow animate__animated animate__bounceInDown" />

                                <div className="starpart">
                                    <div className=" wow animate__animated animate__fadeInRight">
                                        <img src={star} title="star" className="star" alt="Star" />
                                        <img src={star} title="star" className="star" alt="Star" />
                                        <img src={star} title="star" className="star" alt="Star" />
                                        <img src={star} title="star" className="star" alt="Star" />
                                        <img src={star} title="star" className="star" alt="Star" />
                                    </div>
                                    <div className="starRight wow animate__animated animate__fadeInRight">
                                        <h5 className="imgright">3 Years</h5>
                                        <h5 className="imgright">Experience</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="hireIcon mt-5 d-flex aling-items-center justify-content-center">
                                <img src={HiringBG} alt="Hiring Background" className="HiringBG" />
                            </div>
                            <div className="">
                                {/* Assuming BeforeFooterHire is a component */}
                                <BeforeFooterHire className="BeforeFooterHire" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>


            <Footer />
        </div>
        </>
    )
}
export default Hiring;
import { React, SyntheticEvent, useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import Header from "../Component/Header";
import Footer from '../Component/Footer';
import { Col, Container, Row } from "react-bootstrap";
import "./porfolio.css"

import ArrowWhite from "../Images/Home/ArrowWhite.png";
import ArrowBlack from "../Images/Home/ArrowBlack.png";
import { Tab, Tabs } from "@mui/material";

import Rounds from "../Images/Home/Rounds.webp";
import Rounds2 from "../Images/Home/Round2.webp";
import 'animate.css';
import WOW from 'wowjs';
import l1 from "../Images/Porfolio/Logo/1.webp";
import l2 from "../Images/Porfolio/Logo/2.webp";
import l3 from "../Images/Porfolio/Logo/3.webp";
import l4 from "../Images/Porfolio/Logo/4.webp";
import l5 from "../Images/Porfolio/Logo/5.webp";
import l6 from "../Images/Porfolio/Logo/6.webp";
import l7 from "../Images/Porfolio/Logo/7.webp";
import l8 from "../Images/Porfolio/Logo/8.webp";
import l9 from "../Images/Porfolio/Logo/9.webp";
import l10 from "../Images/Porfolio/Logo/10.webp";
import l11 from "../Images/Porfolio/Logo/11.webp";
import l12 from "../Images/Porfolio/Logo/12.webp";
import l13 from "../Images/Porfolio/Logo/13.webp";
import l14 from "../Images/Porfolio/Logo/14.webp";

import a1 from "../Images/Porfolio/App/a1.webp";
import a2 from "../Images/Porfolio/App/a2.webp";
import a3 from "../Images/Porfolio/App/a3.webp";
import a4 from "../Images/Porfolio/App/a4.webp";
import a5 from "../Images/Porfolio/App/a5.webp";
import a6 from "../Images/Porfolio/App/a6.webp";
import a7 from "../Images/Porfolio/App/a7.webp";
import a8 from "../Images/Porfolio/App/a8.webp";
import a9 from "../Images/Porfolio/App/a9.webp";
import a10 from "../Images/Porfolio/App/a10.webp";
import a11 from "../Images/Porfolio/App/a11.webp";
import a12 from "../Images/Porfolio/App/a12.webp";
import a13 from "../Images/Porfolio/App/a13.webp";
import a14 from "../Images/Porfolio/App/a14.webp";
import a15 from "../Images/Porfolio/App/a15.webp";
import a16 from "../Images/Porfolio/App/a16.webp";
import a17 from "../Images/Porfolio/App/a17.webp";


import w1 from "../Images/Porfolio/Web/w1.webp";
import w2 from "../Images/Porfolio/Web/w2.webp";
import w3 from "../Images/Porfolio/Web/w3.webp";
import w4 from "../Images/Porfolio/Web/w4.webp";
import w5 from "../Images/Porfolio/Web/w5.webp";
import w6 from "../Images/Porfolio/Web/w6.webp";
import w7 from "../Images/Porfolio/Web/w7.webp";
import w8 from "../Images/Porfolio/Web/w8.webp";
import w9 from "../Images/Porfolio/Web/w9.webp";
import w10 from "../Images/Porfolio/Web/w10.webp";
import w11 from "../Images/Porfolio/Web/w11.webp";
import w12 from "../Images/Porfolio/Web/w12.webp";

import b1 from "../Images/Porfolio/Book Cover/b1.webp";
import b2 from "../Images/Porfolio/Book Cover/b2.webp";
import b3 from "../Images/Porfolio/Book Cover/b3.webp";
import b4 from "../Images/Porfolio/Book Cover/b4.webp";
import b5 from "../Images/Porfolio/Book Cover/b5.webp";
import b6 from "../Images/Porfolio/Book Cover/b6.webp";
import b7 from "../Images/Porfolio/Book Cover/b7.webp";
import b8 from "../Images/Porfolio/Book Cover/b8.webp";
import b9 from "../Images/Porfolio/Book Cover/b9.webp";

import c1 from "../Images/Porfolio/Business Card/c1.webp";
import c2 from "../Images/Porfolio/Business Card/c2.webp";
import c3 from "../Images/Porfolio/Business Card/c3.webp";
import c4 from "../Images/Porfolio/Business Card/c4.webp";
import c5 from "../Images/Porfolio/Business Card/c5.webp";
import c6 from "../Images/Porfolio/Business Card/c6.webp";
import c7 from "../Images/Porfolio/Business Card/c7.webp";
import c8 from "../Images/Porfolio/Business Card/c8.webp";
import c9 from "../Images/Porfolio/Business Card/c9.webp";

import bn1 from "../Images/Porfolio/bn1.webp";
import bn2 from "../Images/Porfolio/bn2.webp";
import bn3 from "../Images/Porfolio/bn3.webp";
import bn4 from "../Images/Porfolio/bn4.webp";
import bn5 from "../Images/Porfolio/bn5.webp";
import bn6 from "../Images/Porfolio/bn6.webp";
import bn7 from "../Images/Porfolio/bn7.webp";
import bn8 from "../Images/Porfolio/bn8.webp";
import bn9 from "../Images/Porfolio/bn9.webp";




import BeforeFooter from '../Component/BeforeFooter';
import { Link } from 'react-router-dom';
import { PiMouseSimple } from "react-icons/pi";
import Seo from '../Component/Seo';

function Portfolio() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [scrollmouseClass, setScrollmouseClass] = useState("notShown");

    const handleScroll = () => {
        const currentScrollTop = window.scrollY;
        const showThreshold = 400; // Adjust this threshold as needed

        if (currentScrollTop > showThreshold) {
            setScrollmouseClass("Shown");
        } else {
            setScrollmouseClass("notShown");
        }
    };

    const scrollevent = () => {
        const currentScrollTop = window.scrollY;
        if (currentScrollTop > 400) {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [value, setValue] = useState(0);
    const [list1, setList1] = useState([l1, l2, l3, l4, l5, l6, l7, l8, l1, l2, l3, l4, l5, l6, l7, l8]);
    const [list2, setList2] = useState([l9, l10, l11, l12, l13, l14, l9, l10, l11, l12, l13, l14].reverse());

    const [alist1, setAList1] = useState([a1, a2, a3, a4, a5, a6, a7, a8, a9, a1, a2, a3, a4, a5, a6, a7, a8, a9]);
    const [alist2, setAList2] = useState([a10, a11, a12, a13, a14, a15, a16, a17, a10, a11, a12, a13, a14, a15, a16, a17].reverse());

    const [wlist1, setWList1] = useState([w1, w2, w3, w4, w5, w6, w7, w8, w1, w2, w3, w4, w5, w6, w7, w8]);
    const [wlist2, setWList2] = useState([w9, w10, w11, w12, w9, w10, w11, w12].reverse());

    const [blist1, setBList1] = useState([b1, b2, b3, b4, b5, b1, b2, b3, b4, b5]);
    const [blist2, setBList2] = useState([b6, b7, b8, b9, b6, b7, b8, b9].reverse());

    const [clist1, setCList1] = useState([c1, c2, c3, c4, c5, c1, c2, c3, c4, c5]);
    const [clist2, setCList2] = useState([c6, c7, c8, c9, c6, c7, c8, c9].reverse());

    const [bnlist1, setBnList1] = useState([bn1, bn2, bn3, bn4, bn5, bn1, bn2, bn3, bn4, bn5]);
    const [bnlist2, setBnList2] = useState([bn6, bn7, bn8, bn9, bn6, bn7, bn8, bn9].reverse());

    const handleChange = (event: SyntheticEvent , newValue: number) => {
        setValue(newValue);
    };


    useEffect(() => {
        new WOW.WOW().init();
      }, []);

    return (
        <>
        <Seo
        title="IT Solutions & Business Services Company " 
        description="IT Solutions & Business Services Company We Build innovative web and mobile apps. " 
        keywords="technology consulting ,Services , web , innovative , IT Solutions, software  "
      />
        <div onScroll={handleScroll}>
            <div className={`upperMouse ${scrollmouseClass}`}> ,
                <PiMouseSimple className="upperMouseIcon" onClick={scrollevent} />
            </div>
            <div className={`MainHome MainHome1`}>
                <Header />
                <Container>
                    <Row className="wow animate__animated animate__fadeInUp"
                        style={{ animationDuration: '2s' }}
                    >
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="mt-5 pt-5">
                            <h1 className="ServiceSliderHead">
                                OUR WALL OF PRIDE AND CREATIVITY
                            </h1>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="py-5">
                            <h3 className="ServiceSliderPara">
                                When we say that Snepitech is a leading custom software development solution provider in the India, there’s a reason behind it - Our work. Check out our portfolio where you will come across some of the mind-blowing projects we have been a part of.
                            </h3>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <Link to={"/RequestQuote"} style={{ textDecoration: "none" }}>
                                <button variant="outline-success" className="AboutButton">
                                    Get a Quote
                                    <img src={ArrowWhite} alt='ArrowWhite' className="ms-3 arrow" />
                                </button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="mt-5 Line3Parent">
                <Container fluid>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div className="AboutServices3">
                                <div className="Rounds"><img src={Rounds} alt="rounds" /></div>
                                <div className="Rounds2"><img src={Rounds2} alt="rounds2" /></div>
                            </div>
                            <Container>
                                <Row>
                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                        <h1 className="ProcessHead1 wow animate__animated animate__fadeInUp">Our Wall Of Pride</h1>
                                    </Col>
                                </Row>

                                <Row className='mt-5'>
                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" >
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                            aria-label="scrollable force tabs example"
                                        >
                                            <Tab label="App Design" />
                                            <Tab label="Web Design" />
                                            <Tab label="Logo Design" />
                                            <Tab label="Book Cover" />
                                            <Tab label="Business Card" />
                                            <Tab label="Banner" />
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Container>
                            {/* Content for each tab */}
                            <Row className='my-5'>
               
                                <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                    {value === 0 && (
                                        <>
                                            <Marquee direction="left" id="myMarquee" scrollamount="100" className='wow animate__animated animate__fadeInUp animate__delay-2s' style={{ animationDuration: '1.5s' }}>
                                                {alist1.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimage marqueeimageList1 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                            <Marquee direction="right" id="myMarquee" scrollamount="100" className='wow animate__animated animate__fadeInDown animate__delay-2s' style={{ animationDuration: '1.5s' }}>
                                                {alist2.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimage marqueeimageList2 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                        </>
                                    )}
                                    {value === 1 && (
                                        <>
                                            <Marquee direction="left" id="myMarquee">
                                                {wlist1.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimage marqueeimageList1 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                            <Marquee direction="right" id="myMarquee">
                                                {wlist2.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimage marqueeimageList2 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                        </>
                                    )}
                                    {value === 2 && (
                                        <>
                                            <Marquee direction="left" id="myMarquee">
                                                {list1.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimageLogo marqueeimageList1 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                            <Marquee direction="right" id="myMarquee">
                                                {list2.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimageLogo marqueeimageList2 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                        </>
                                    )}
                                    {value === 3 && (
                                        <>
                                            <Marquee direction="left" id="myMarquee">
                                                {blist1.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimage marqueeimageList1 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                            <Marquee direction="right" className="marquee2" id="myMarquee">
                                                {blist2.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimage marqueeimageList2 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                        </>
                                    )}
                                    {value === 4 && (
                                        <>
                                            <Marquee direction="left" id="myMarquee">
                                                {clist1.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimage marqueeimageList1 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                            <Marquee direction="right" id="myMarquee">
                                                {clist2.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimage marqueeimageList2 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                        </>
                                    )}
                                    {value === 5 && (
                                        <>
                                            <Marquee direction="left" id="myMarquee">
                                                {bnlist1.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimage marqueeimageList1 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                            <Marquee direction="right" id="myMarquee">
                                                {bnlist2.map((image, index) => (
                                                    <div key={index} className="image_wrapper">
                                                        <img src={image} alt={`Image ${index}`} className='marqueeimage marqueeimageList2 ' />
                                                    </div>
                                                ))}
                                            </Marquee>
                                        </>
                                    )}
                                </Col>

                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>



            <BeforeFooter />

            <Footer />

        </div>
        </>
    )
}
export default Portfolio;
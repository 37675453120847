import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import { Col, Container, Row } from "react-bootstrap";

import BeforeFooter from "../Component/BeforeFooter";
import Footer from "../Component/Footer";
import './WhySnepitech.css';
import 'animate.css';
import WOW from 'wowjs';

import Rounds from "../Images/Home/Rounds.webp";
import Rounds2 from "../Images/Home/Round2.webp";

import ArrowWhite from "../Images/Home/ArrowWhite.png";
import ArrowBlack from "../Images/Home/ArrowBlack.png";

import WhySnepitechGrp from "../Images/WhySnepitech/WhySnepitechGrp.png";

import w1 from "../Images/WhySnepitech/1.png";
import w2 from "../Images/WhySnepitech/2.png";
import w3 from "../Images/WhySnepitech/3.png";
import w4 from "../Images/WhySnepitech/4.png";
import w5 from "../Images/WhySnepitech/5.png";
import w6 from "../Images/WhySnepitech/6.png";
import { Link } from "react-router-dom";
import { PiMouseSimple } from "react-icons/pi";
import { LocalDrinkSharp } from "@mui/icons-material";
import Seo from "../Component/Seo";

function WhySnepitech() {

    const [Pwidth, setPWidth] = useState("web")
    const [scrollmouseClass, setScrollmouseClass] = useState("notShown");

    const handleScroll = () => {
        const currentScrollTop = window.scrollY;
        const showThreshold = 400; // Adjust this threshold as needed

        if (currentScrollTop > showThreshold) {
            setScrollmouseClass("Shown");
        } else {
            setScrollmouseClass("notShown");
        }
    };

    const scrollevent = () => {
        const currentScrollTop = window.scrollY;
        if (currentScrollTop > 400) {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const whygrp = [
        {
            img: w1,
            head: "Innovation",
            des: "We believe in adding value and innovation to each project we take up, which helps us serve our customers better."
        },
        {
            img: w2,
            head: "Customer-First",
            des: "For Snepitech,customers always come first. Our focus lies on imparting the best experience to our customers, no matter the project."
        },
        {
            img: w3,
            head: "Value For Money",
            des: "We provide top-rated software development services at budget-friendly rates."
        },
        {
            img: w4,
            head: "Global Talent Pool",
            des: "The team at Snepitech holds an experience of working with international customers along with understanding of global development protocols."
        },
        {
            img: w5,
            head: "Commitment",
            des: "We are committed to helping our customers to the best of our abilities and technology blend."
        },
        {
            img: w6,
            head: "Quality-Focused",
            des: "We at Vasundhara, ensure quality over quantity. Solid products, stable development lifecycle and dedication helps us achieve quality."
        }
    ]

    useEffect(() => {
        const updateSettings = () => {
            if (window.innerWidth < 768) {
                setPWidth("small");
                console.log("small");
            } else {
                setPWidth("web");
                console.log("web");
            }
        };

        // Initial setup
        updateSettings();

        // Update settings on window resize
        window.addEventListener("resize", updateSettings);

        // Cleanup
        return () => {
            window.removeEventListener("resize", updateSettings);
        };
    }, []);


    useEffect(() => {
        new WOW.WOW().init();
      }, []);

    return (
        <>
        <Seo
        title="WHY SNEPITECH " 
        description=" The team at Snepitech has consistently aimed to bring innovation and expertise to the table to deliver the best services to customers. Here are the top reasons to choose Snepitech. " 
        keywords="WHY SNEPITECH , bring , services , innovation , tech consulting , development , services "
      />
        <div onScroll={handleScroll}>
            <div className={`upperMouse ${scrollmouseClass}`}>
                <PiMouseSimple className="upperMouseIcon" onClick={scrollevent} />
            </div>
            <div className={`MainHome MainHome1 MainHomeAbout`}>
                <Header />
                <Container>
                    <Row className="wow animate__animated animate__fadeInUp"
                        style={{ animationDuration: '2s' }}>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="mt-5 pt-5">
                            <h1 className="ServiceSliderHead">
                                WHY SNEPITECH
                            </h1>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="py-5">
                            <h3 className="ServiceSliderPara">
                                The team at Snepitech has consistently aimed to bring innovation and expertise to the table to deliver the best services to customers. Here are the top reasons to choose Snepitech.
                            </h3>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <Link to={"/RequestQuote"} style={{ textDecoration: "none" }}>
                                <button variant="outline-success" className="AboutButton">
                                    Get a Quote
                                    <img src={ArrowWhite} alt="ArrowWhite" className="ms-3 arrow" />
                                </button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="Line3Parent">
                <Container fluid>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div className="AboutServices3">
                                <div className="Rounds"><img src={Rounds} alt="rounds" /></div>
                                <div className="Rounds2"><img src={Rounds2} alt="rounds2" /></div>
                            </div>
                            <Container>
                                {Pwidth == "web" ?
                                    <Row>
                                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-center">
                                            <img src={WhySnepitechGrp} alt="WhySnepitechGrp" className="SnepigrpImage wow animate__animated animate__slideInUp" style={{ animationDuration: '2s' }} />
                                        </Col>
                                    </Row>
                                    :
                                    <>
                                        {whygrp.map((i, index) => (
                                            <Row key={index} className={`my-5 py-3 d-flex align-items-center ${index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
                                                <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="6">
                                                    <div className="">
                                                        <img src={i.img} alt={i.img} />
                                                    </div>
                                                </Col>
                                                <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="6">
                                                    <div className="">
                                                        <h5 style={{ color: "#388087" }}>{i.head}</h5>
                                                        <p style={{ color: "#6FB3B8" }}>{i.des}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </>
                                }
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

            <BeforeFooter />

            <Footer />
        </div>
        </>
    )
}
export default WhySnepitech;
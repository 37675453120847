import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import './RequestQuote.css';

import Rounds from "../Images/Home/Rounds.webp";
import Rounds2 from "../Images/Home/Round2.webp";

import ArrowWhite from "../Images/Home/ArrowWhite.png";
import ArrowBlack from "../Images/Home/ArrowBlack.png";
import { Col, Container, Row } from "react-bootstrap";

import footeruplode from "../Images/Footer/Vector.png";
import BeforeFooter from "../Component/BeforeFooter";

import mail from "../Images/Footer/mail.png";
import call from "../Images/Footer/call.png";
import snepitech from "../Images/Footer/snepitech.png";

import { Api } from "../Api/Api";
import { Link } from "react-router-dom";
import Seo from "../Component/Seo";
const { ClientWeb } = Api;

async function ClientWebInsert(credentials) {
    return fetch(ClientWeb.Insert, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

function RequestQuote() {

    const [strFname, setStrFname] = useState("");
    const [strLname, setStrLname] = useState("");
    const [strEmail, setStrEmail] = useState("");
    const [intMobile, setIntMobile] = useState(null);
    const [strService, setStrService] = useState("");
    const [intBudget, setIntBudget] = useState();
    const [strDescription, setStrDescription] = useState("");
    const [chooseFile, setchooseFile] = useState();
    const [fileName, setFileName] = useState("Choose file");


    const refreshFn = () => {
        setStrFname("")
        setStrLname("")
        setStrEmail("")
        setIntMobile("")
        setStrService("")
        setIntBudget("")
        setStrDescription("")
        setchooseFile("")
    }
    let numericValue = "";

    const sendMsg = async () => {
        let numericValue = intMobile;
        let abc = numericValue.toString().length + 1;

        if (strFname === "") {
            toast.error("First Name is Required.");
        } else if (strLname === "") {
            toast.error("Last Name is Required.");
        } else if (strEmail === "") {
            toast.error("Email is Required.");
        } else if (!isValidEmail) {
            toast.error("Please Enter valid Email Address.");
        } else if (intMobile === "" || intMobile === null) {
            toast.error("Mobile is Required.");
        } else if (abc < 10) {
            console.log(abc);
            toast.error('Mobile number must have at least 10 digits');
        } else if (strService === "") {
            toast.error("Service is Required.");
        } else if (intBudget === "") {
            toast.error("Budget is Required.");
        } else if (strDescription === "") {
            toast.error("Description is Required.");
        } else {
            const data = await ClientWebInsert({
                name: strFname,
                lastname: strLname,
                email: strEmail,
                mobilenumber: parseInt(intMobile),
                service: strService,
                budget: parseInt(intBudget),
                message: strDescription
            });
            if (data) {
                toast.success("Details Send Successfully..");
            }
            await refreshFn();
        }
    }

    useEffect(() => {
        window.scrollTo(0, 700);
    }, []);

    const MobileValidation = (e) => {
        numericValue = e.replace(/[^0-9]/g, '');

        const validMobile = numericValue.slice(0, 10);
        setIntMobile(validMobile);
    };



    const BudgetValidation = (e) => {
        const numericValue = e.replace(/[^0-9]/g, '');
        setIntBudget(numericValue);
    };

    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleInputChange = (e) => {
        const emailValue = e.target.value;
        setStrEmail(emailValue);

        // Basic email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(emailValue));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];  // Get the first file
        if (file) {
            setFileName(file.name);  // Update state with file name
            toast.success("document uploaded successfully.")
        } else {
            setFileName("");  // Clear state if no file is uploaded
            toast.success("no document uploaded.")
        }
    };

    const handleEmailClick = () => {
        const emailAddress = "info@snepitech.com";
        const subject = "Inquiry";
        const body = "hello..";

        // Compose the mailto URL with the email address, subject, and body
        const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        // Open the default email client with the mailto URL
        window.location.href = mailtoUrl;
    };
    const handlePhoneCall = () => {
        const phoneNumber = "+919426114378";

        // Compose the tel URL with the phone number
        const telUrl = `tel:${phoneNumber}`;

        // Open the default phone app with the tel URL
        window.location.href = telUrl;
    };

    return (
        <>
                    <Seo
        title="SNEPITECH PROVIDES SERVICE " 
        description="We provide customized pricing for each project and request we receive. Fill the form below and receive a personalized quote for your requirements " 
        keywords="project , 24/7 hours , Message"
      />
            <div className={`MainHome MainHome1 MainHomeRequest`}>
                <Header />
                <Container>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="mt-5 pt-5">
                            <h1 className="ServiceSliderHead">
                                Request for quote
                            </h1>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="py-5">
                            <h3 className="ServiceSliderPara">
                                We provide customized pricing for each project and request we receive. Fill the form below and receive a personalized quote for your requirements.
                            </h3>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="mt-5 Line3Parent mb-5">
                <Container fluid>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div className="AboutServices3">
                                {/* <div className="Rounds"><img src={Rounds} alt="rounds" /></div>
                                <div className="Rounds2"><img src={Rounds2} alt="rounds2" /></div> */}
                            </div>
                            <Container>
                                <Row>
                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                        <h1 className="ProcessHead1">your details</h1>
                                    </Col>
                                </Row>

                                <Row className="mt-5">
                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="py-2">
                                        <div className="FieldBody">

                                            <Row>
                                                <Col xxl="3" xl="3" lg="4" md="12" sm="12" xs="12">
                                                    <div className="contactDetail p-3" >
                                                        <div>
                                                            <h4 style={{ color: "#6FB3B8" }} align="left">Contact Information</h4>
                                                            <p align="left" style={{ fontSize: "16px", color: "#5F5F5F" }}>
                                                                Fill up the from and Our team
                                                                will get back to you within
                                                                24 hours.
                                                            </p>
                                                        </div>
                                                        <div style={{ color: "#6FB3B8", fontSize: "12px", textAlign: "left" }}>
                                                            <Link className="RequestInfo" onClick={handleEmailClick}>
                                                                <Row className="py-1">
                                                                    <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                        <img src={mail} alt="mail" />
                                                                    </Col>
                                                                    <Col xxl="10" xl="10" lg="10" md="10" sm="10" xs="10">
                                                                        <h6 className="smallColor mb-0">info@snepitech.com</h6>
                                                                    </Col>
                                                                </Row>
                                                            </Link>
                                                            <Link to={"tel:+919426114378"} className="RequestInfo" target="_blank">
                                                                <Row className="py-1">
                                                                    <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                        <img src={call}alt="call" />
                                                                    </Col>
                                                                    <Col xxl="10" xl="10" lg="10" md="10" sm="10" xs="10">
                                                                        <h6 className="smallColor mb-0">+91 94261-14378</h6>
                                                                    </Col>
                                                                </Row>
                                                            </Link>
                                                            <Link to={"https://secure.skype.com/portal/overview"} target="_blank" className="RequestInfo">
                                                                <Row className="py-1">
                                                                    <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                        <img src={snepitech} alt="snepitech" />
                                                                    </Col>
                                                                    <Col xxl="10" xl="10" lg="10" md="10" sm="10" xs="10">
                                                                        <h6 className="smallColor mb-0">snepitech</h6>
                                                                    </Col>
                                                                </Row>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col xxl="9" xl="9" lg="8" md="12" sm="12" xs="12">
                                                    <div className="form-wrapper">
                                                        <Row>

                                                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                                <Row>
                                                                    <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className="fname ">
                                                                        <input type="text" className="inputfield" placeholder="First Name *" value={strFname} onChange={(e) => { setStrFname(e.target.value) }} />
                                                                    </Col>
                                                                    <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                                                        <input type="text" className="inputfield ms-1 me-0" placeholder="Last Name *" value={strLname} onChange={(e) => { setStrLname(e.target.value) }} />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                                <input
                                                                    type="text"
                                                                    className={`inputfield ${isValidEmail ? '' : 'invalid'}`}
                                                                    placeholder="Enter Your Valid Email *"
                                                                    value={strEmail}
                                                                    onChange={handleInputChange}
                                                                />
                                                                {!isValidEmail && <p className="error-message text-start px-4" style={{ color: "#99221a" }}>Please Enter valid Email Address.</p>}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                                <input type="text" className="inputfield" placeholder="Enter Your Valid Mobile Number *" value={intMobile} onChange={(e) => { MobileValidation(e.target.value) }} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                                <input type="text" className="inputfield" placeholder="Enter Service *" value={strService} onChange={(e) => { setStrService(e.target.value) }} />
                                                            </Col>
                                                        </Row>
                                                        <Row><Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                            <input type="text" className="inputfield" placeholder="Enter Budget *" value={intBudget} onChange={(e) => { BudgetValidation(e.target.value) }} />
                                                        </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                                {/* <input type="text" className="inputfield" placeholder="Enter Message " /> */}
                                                                <textarea className="inputfield" rows={6} placeholder="Enter Description *" value={strDescription} onChange={(e) => { setStrDescription(e.target.value) }} ></textarea>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0 inputRelativeFile">
                                                                {/* <input type="text" className="inputfield inputFieldFile" placeholder="Choose file " />
                                                                <span className="uplodeIcon">
                                                                    <img src={footeruplode} />
                                                                </span>
                                                                <span className="uplodeIcon">
                                                                    <input type="file" className="opacity0" />
                                                                </span> */}
                                                                <input
                                                                    type="file"
                                                                    className="inputfield inputFieldFile"
                                                                    onChange={handleFileChange}
                                                                    style={{ display: 'none' }}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className="inputfield inputFieldFile"
                                                                    placeholder={fileName}
                                                                    readOnly />
                                                                <span
                                                                    className="uplodeIcon"
                                                                    onClick={() => {
                                                                        document.querySelector('.inputfield.inputFieldFile[type="file"]').click();
                                                                    }}
                                                                >
                                                                    <img src={footeruplode} alt="Upload" />
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-end mt-3">
                                                                <button variant="outline-success" className="AboutButton ButtonFooterPopUp" onClick={sendMsg}>
                                                                    Send Message
                                                                    <img src={ArrowWhite} alt="ArrowWhite" className="ms-2 arrow arrowfooterPopup" />
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

            <BeforeFooter />

            <Footer />

            <ToastContainer
                position="bottom-left"
                hideProgressBar={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                limit={1}
            />

        </>
    )
}
export default RequestQuote;
import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './BeforeFooter.css';

import ArrowBlack from "../Images/Home/ArrowBlack.png";
import ArrowWhite from "../Images/Home/ArrowWhite.png";
import footeruplode from "../Images/Footer/Vector.png";
import { AiOutlineCloseCircle } from "react-icons/ai";

import Flutter from "../Images/Services/Flutter.png";
import reactjsicon from "../Images/Services/Angular.png";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import mail from "../Images/Footer/mail.png";
import call from "../Images/Footer/call.png";
import snepitech from "../Images/Footer/snepitech.png";

import { ToastContainer, toast } from "react-toastify";

import { Api } from "../Api/Api";
import moment from "moment";
const { EmpWeb } = Api;

async function EmpWebInsert(credentials) {
    return fetch(EmpWeb.Insert, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

function BeforeFooterHire() {

    const [getStarted, setGetStarted] = useState(false);
    const [joiningDate, setJoiningDate] = useState("");

    const [designation, setDesignation] = useState("");
    const [experience, setExperience] = useState("");
    const [fName, setFname] = useState("");
    const [Lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [city, setCity] = useState("");
    const [Message, setMessage] = useState("");
    const [CV, setCV] = useState("");
    const [fileName, setFileName] = useState("Choose file");

    const applyFn = async () => {
        console.log("hello");
        if (fName == "") {
            toast.error("First Name is Required.")
        } else if (Lname == "") {
            toast.error("Last Name is Required.")
        } else if (email == "") {
            toast.error("Email is Required.")
        } else if (phone == "") {
            toast.error("Mobile Number is Required.")
        } else if (city == "") {
            toast.error("City Name is Required.")
        } else if (designation == "") {
            toast.error("Designation is Required.")
        } else if (experience == "") {
            toast.error("Expirience is Required.")
        } else {
            const data = await EmpWebInsert({
                name: fName,
                lastname: Lname,
                email: email,
                mobilenumber: parseInt(phone),
                city: city,
                experience: parseInt(experience),
                message: Message,
                designation: designation,
                qtype: "Insert",
                joiningdate: moment().format("DD-MM-YYYY"),
            })
            if (data) {
                toast.success("Details Send Successfully..")
            }
            await resetFn();
        }
    }

    const SPArray = [
        {
            img: Flutter,
            position: "Flutter Developers",
            Experience: "2-4 Year",
            numberofOpening: "2",
            Qualification: "Graduate"
        },
        {
            img: reactjsicon,
            position: "ReactJS Developers",
            Experience: "0-5 Year",
            numberofOpening: "5",
            Qualification: "Graduate"
        }
    ]

    const resetFn = () => {
        setFname("");
        setLname("");
        setEmail("");
        setPhone("");
        setCV("");
        setCV("");
        setMessage("");
        setDesignation("");
        setExperience("");
        setCity("");
        setFileName("Choose file")
    }

    const handlePhoneChange = (e) => {
        const input = e.target.value;
        const numericInput = input.replace(/\D/g, '');
        setPhone(numericInput);
    }

    const handleClose = () => {
        // Handle closing of the modal, e.g., update state to hide the modal
        setGetStarted(false);
    };

    const handleChange = (e) => {
        setDesignation(e.target.value); // State ko update karna
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];  // Get the first file
        if (file) {
            setFileName(file.name);  // Update state with file name
        } else {
            setFileName("");  // Clear state if no file is uploaded
        }
    };
    return (
        <div className="BeforeFooterSection mt-0">
            <Container>
                <Row>
                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                        <Row className="BgBeforeFooter">
                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                <h2 className="BeforeFooterHeading BeforeFooterHeading1">
                                    How can we help you develop your career?
                                </h2>
                            </Col>
                            <Col xxl="9" xl="8" lg="8" md="8" sm="12" xs="12" className="Head2Padding">
                                <h1 className="BeforeFooterHeading BeforeFooterHeading2" style={{ fontWeight: "500" }}>Let's innovate for your technology needs with
                                    Snepitech.
                                </h1>
                            </Col>
                            <Col xxl="3" xl="4" lg="4" md="4" sm="12" xs="12" className="ServiceButtonFooter">
                                <button variant="outline-success" className="AboutButtonFooter" onClick={() => { setGetStarted(true) }}>
                                    Get Started
                                    <img src={ArrowBlack} className="ms-3 arrowback" />
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Modal size="xl" aria-labelledby="example-modal-sizes-title-sm" centered className="text-center mt-5" show={getStarted} id="modelSize">
                <Modal.Body>
                    <AiOutlineCloseCircle style={{ color: "#388087" }} onClick={() => { setGetStarted(false) }} className="closeBtn" />
                    <Container>

                        <Row>
                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="py-2">
                                <div className="FieldBody">
                                    <Row>
                                        <Col xxl="3" xl="3" lg="4" md="12" sm="12" xs="12">
                                            <div className="contactDetail p-3" >
                                                <div>
                                                    <h5 style={{ color: "#6FB3B8" }} align="left">Our Requirements</h5>
                                                    <p align="left" style={{ fontSize: "15px", color: "#5F5F5F" }}>
                                                        Fill up the from and Our team
                                                        will get back to you within
                                                        24 hous.
                                                    </p>
                                                </div>
                                                <div style={{ color: "#6FB3B8", fontSize: "12px", textAlign: "left" }} className="contactInfo" >
                                                    <Row >
                                                        <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                            <img src={mail} />
                                                        </Col>
                                                        <Col xxl="10" xl="10" lg="10" md="10" sm="10" xs="10">
                                                            <h6>info@snepitech.com</h6>
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                            <img src={call} />
                                                        </Col>
                                                        <Col xxl="10" xl="10" lg="10" md="10" sm="10" xs="10">
                                                            <h6>+91 94261-14378</h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                            <img src={snepitech} />
                                                        </Col>
                                                        <Col xxl="10" xl="10" lg="10" md="10" sm="10" xs="10">
                                                            <h6>snepitech</h6>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl="9" xl="9" lg="8" md="12" sm="12" xs="12">
                                            <div className="form-wrapper">
                                                <Row>
                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                        <Row>
                                                            <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                                                <input type="text" className="inputfield" placeholder="First Name" value={fName} onChange={(e) => { setFname(e.target.value) }} />
                                                            </Col>
                                                            <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                                                <input type="text" className="inputfield" placeholder="Last Name" value={Lname} onChange={(e) => { setLname(e.target.value) }} />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                        <input type="text" className="inputfield" placeholder="Enter Your Valid Email " value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                        <input type="text" className="inputfield" placeholder="Enter Your Valid Mobile Number " value={phone} onChange={(e) => { handlePhoneChange(e) }} maxLength={10} minLength={10} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                        <input type="text" className="inputfield" placeholder="Enter City Name " value={city} onChange={(e) => { setCity(e.target.value) }} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                        <input type="text" className="inputfield" placeholder="Enter Experience " value={experience} onChange={(e) => { setExperience(e.target.value) }} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                               
                                                        <select
                                                            className="inputfield"
                                                            id="position-select"
                                                            value={designation}
                                                            onChange={handleChange}
                                                        >
                                                            {/* Dropdown ke liye options array se bante hain */}
                                                            <option value="" disabled>
                                                                Select Position
                                                            </option>
                                                            {SPArray.map((item, index) => (
                                                                <option key={index} value={item.position}>
                                                                    {item.position}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                </Row>
                                            
                                                <Row>
                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0">
                                                        {/* <input type="text" className="inputfield" placeholder="Enter Message " /> */}
                                                        <textarea className="inputfield" rows={4} placeholder="Enter Message" value={Message} onChange={(e) => { setMessage(e.target.value) }}></textarea>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="px-0 inputRelativeFile">
                                                      
                                                        <input
                                                            type="file"
                                                            className="inputfield inputFieldFile"
                                                            onChange={handleFileChange}
                                                            style={{ display: 'none' }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="inputfield inputFieldFile"
                                                            placeholder={fileName}
                                                            readOnly />
                                                        <span
                                                            className="uplodeIcon"
                                                            onClick={() => {
                                                                document.querySelector('.inputfield.inputFieldFile[type="file"]').click();
                                                            }}
                                                        >
                                                            <img src={footeruplode} alt="Upload" />
                                                        </span>
                                                    </Col>
                                                </Row>
                                           
                                                <Row>
                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-end mt-3">
                                                        <button variant="outline-success" className="AboutButton ButtonFooterPopUp" onClick={resetFn} style={{ marginRight: "10px" }}>
                                                            Reset
                                                        </button>
                                                        <button variant="outline-success" className="AboutButton ButtonFooterPopUp" onClick={() => { applyFn() }}>
                                                            Apply Now
                                                            <img src={ArrowWhite} className="ms-2 arrow arrowfooterPopup" />
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <ToastContainer
                position="bottom-left"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={1}
            />
        </div>
    )
}
export default BeforeFooterHire;
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import WhiteLogo from "../Images/Home/LogoWhite.png";
import location from "../Images/Home/LocationMap.png";
import whatsapp from "../Images/Footer/whatsapp.png";
import instagram from "../Images/Footer/instagram.png";
import linkdin from "../Images/Footer/linkedin.png";
import twiter from "../Images/Footer/twiter.png";
import { FaInstagram } from "react-icons/fa";
import { MdWhatsapp } from "react-icons/md";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";

import './Footer.css';
import { Link } from "react-router-dom";
// import Seo from "./Seo";

function Footer() {

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
        <>
                           {/* <Seo
        title="IT Solutions & Business Services Company " 
        description="BEST SOFTWARE COMPANY IN SURAT,GUJRAT,INDIA AND OUR WORLD " 
        keywords="world ,mota, varachha , Surat , gujrat , technology consulting"
      /> */}
        <Container fluid className="px-0">
            <Row className="Footer">
                <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                    <Container>
                        <Row className="FooterY">
                            <Col xxl="6" xl="6" lg="6" md="4" sm="12" xs="12" className="d-flex justify-content-left align-items-center smallFooter px-0">
                                <div align="left" className="px-0">
                                    <Link to={"/"} style={{ textDecoration: "none", color: "#ffffff" }}>
                                        <img src={WhiteLogo} title="Logo" alt="WhiteLogo" className="WhiteFooterLogo" />
                                        <h3 style={{ color: "#ffffff" }} className="mt-3 footerLogoTexts">SNAP FOR TECHNOLOGY</h3>
                                    </Link>
                                </div>
                            </Col>
                            <Col xxl="6" xl="6" lg="6" md="8" sm="12" xs="12" className="d-flex px-0">
                                <div className="Location px-0">
                                    <h5 align="left">Address</h5>
                                    <p align="left" style={{ fontWeight: 300, fontSize: "14px" }}>204, Golden Square, Nr. D-mart  , Digital Valley (Mota-Varachha), Surat , Gujarat-394101</p>
                                    <div className="px-0">
                                        <a href={"https://www.google.com/maps/place/SnepiTech/@21.2396016,72.8664532,17z/data=!3m1!4b1!4m6!3m5!1s0x3be04f48cd479e1f:0x492dddad1e5554ea!8m2!3d21.2395967!4d72.8713241!16s%2Fg%2F11s_zkqmyh?hl=en&entry=ttu"} target="_blank">
                                            <img src={location} alt="location"/>
                                        </a>
                                    </div>
                                </div>

                                <div className="Services ms-5">
                                    <h5 align="left">Services</h5>
                                    <Link to={"/best-website-services-in-surat"} style={{ textDecoration: "none", color: "#ffffff" }}>
                                        <p align="left" style={{ fontWeight: 300, fontSize: "14px" }} className="footerServices">
                                            Web Development
                                        </p>
                                    </Link>
                                    <Link to={"/best-application-services-in-surat"} style={{ textDecoration: "none", color: "#ffffff" }} className="footerServices">
                                        <p align="left" style={{ fontWeight: 300, fontSize: "14px" }}>
                                            Mobile App Development
                                        </p>
                                    </Link>
                                    <Link to={"/best-software-services-in-surat"} style={{ textDecoration: "none", color: "#ffffff" }} className="footerServices">
                                        <p align="left" style={{ fontWeight: 300, fontSize: "14px" }}>
                                            Software Development
                                        </p>
                                    </Link>
                                    <Link to={"/best-designing-in-surat"} style={{ textDecoration: "none", color: "#ffffff" }} className="footerServices">
                                        <p align="left" style={{ fontWeight: 300, fontSize: "14px" }}>
                                            UI/UX Designer
                                        </p>
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <Row className="CopyrightRow align-items-center justify-content-center text-align-center">
                            <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="12">
                                <p className="FooterCopyright mb-0">
                                    © {currentYear} All Rights Reserved - snepitech.com
                                </p>
                            </Col>
                            <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="12">
                                <div className="socialMediaIcons1 mb-0">
                                    <a href="https://www.instagram.com/accounts/login/?next=/snepitech/" target="_blank" className="footerIcon">
                                        {/* <img src={instagram} alt="instagram" width="18px" /> */}
                                        <FaInstagram style={{color:"#f5f4f0", fontSize: "20px" }} />
                                    </a>
                                <a href="https://wa.me/919426114378?text=Hello,%20Priyank%20Gadhiya%20" target="_blank" className="footerIcon">
                                    {/* <img src={whatsapp} alt="whatsapp" width="16px" /> */}
                                    <MdWhatsapp style={{color:"#f5f4f0", fontSize: "20px" }} />
                                </a>
                                <a href="https://www.linkedin.com/company/snepitech/mycompany/?viewAsMember=true" target="_blank" className="footerIcon">
                                    {/* <img src={linkdin} alt="linkedin" width="18px" /> */}
                                    <FaLinkedinIn style={{color:"#f5f4f0", fontSize: "20px" }} />
                                </a>
                                <a href="https://twitter.com/snepitech" target="_blank" className="footerIcon">
                                    {/* <img src={twiter} alt="twitter" width="16px" /> */}
                                    <FaXTwitter style={{color:"#f5f4f0", fontSize: "20px" }} />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
        </Container >
        </>
    )
}
export default Footer;
export let serviceUrl = "http://43.249.231.225:8090";


export const Api = {
    ClientWeb: {
        Insert: `${serviceUrl}/ClientWebsite/Insert`,
    },
    EmpWeb:{
        Insert: `${serviceUrl}/EmpWebsites/Insert`,
    }
}
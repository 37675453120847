import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { Container } from "react-bootstrap";
import Arrow from "../Images/Home/Arrow.png";
import s1 from "../Images/Header/coding.png";
import s2 from "../Images/Header/mobile-app.png";
import s3 from "../Images/Header/software-development.png";
import s4 from "../Images/Header/ux-design.png";

import c1 from "../Images/Header/enterprise.png";
import c2 from "../Images/Header/conversation.png";
import c3 from "../Images/Header/goal.png";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";

import White from '../Images/Header/White.svg';
import snepitech from '../Images/Header/Snepitech.svg'

import './Header.css';

import Logo from "../Images/Logo.png";
import { Link } from "react-router-dom";

function CustomDropdownToggle({ children, url }) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ cursor: 'pointer', color: "#6FB3B8", fontSize: "14px", ...(url === "Services" && isHovered ? { color: 'blue' } : {}) }}
            className="ToggleIcon toggleCustomIcon"
        >
            {children}
            {url === "Services" ? <FaAngleUp /> : <FaAngleDown />}
        </div>
    );
}

function CustomDropdownToggle1({ children, url }) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ cursor: 'pointer', color: "#6FB3B8", fontSize: "14px", ...(url === "Company" && isHovered ? { color: 'red' } : {}) }}
            className="ToggleIcon toggleCustomIcon"
        >
            {children}
            {url === "Company" ? <FaAngleUp /> : <FaAngleDown />}
        </div>
    );
}

function Header() {

    const [url, setUrl] = useState("/");
    const [isScrolled, setIsScrolled] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setIsDropdownOpen(false);
    };

    const dropdownMenus = document.querySelectorAll('.dropdown-menu');

    dropdownMenus.forEach(menu => {
        menu.style.display = 'block';
        menu.style.visibility = 'visible';
    });

    useEffect(() => {



        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        const handleOrientationChange = () => {
            setScreenWidth(window.innerWidth);
        };
        console.log(window.innerWidth);

        // Initial width
        setScreenWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleOrientationChange);

        // Cleanup function to remove event listeners when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);


    useEffect(() => {

        if (window.location.pathname == "/") {
            setUrl("Home")
        } else if (
            window.location.pathname == "/best-website-services-in-surat" ||
            window.location.pathname == "/best-application-services-in-surat" ||
            window.location.pathname == "/best-software-services-in-surat" ||
            window.location.pathname == "/best-designing-in-surat"
        ) {
            setUrl("Services")
        }
        else if (window.location.pathname == "/best-company-for-technology") {
            setUrl("Technologies")
        }
        else if (window.location.pathname == "/Portfolio") {
            setUrl("Portfolio")
        }
        else if (window.location.pathname == "/Hiring") {
            setUrl("Hiring")
        }
        else if (
            window.location.pathname == "/AboutSnepitech" ||
            window.location.pathname == "/best-services-for-website-and-applications" ||
            window.location.pathname == "/GrowthAtSnepitech"
        ) {
            setUrl("Company")
        }

        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, [])


    const headerClass = `Header ${isScrolled ? 'transparent' : ''}`;

    return (
        <>
            <Navbar expand="lg" className={headerClass}>
                <Container>
                    <Navbar.Brand href="">
                        <Link to={"/"}>
                            <img src={White} width={"36px"} height={"36px"} alt="White" className="logo1" />
                            <img src={snepitech} alt="snepitech" className="logo2" style={{ paddingLeft: "7px", width: "115px" }} />
                        </Link>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="navbarScroll" />

                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="m-auto my-2 my-lg-0 Menu1"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link>
                                <Link to={"/"} className={`Menus mainMenu ${url == "Home" ? "Active" : ""}`}>
                                    Home
                                </Link>
                            </Nav.Link>

                            <div className="mainsubMenu" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                <NavDropdown title="Services" id="navbarScrollingDropdown" className={`Menus mainMenu ${url == "Services" ? "Active" : ""}`} show={isDropdownOpen} >
                                    <NavDropdown.Item className="subMenu">
                                        <Link to={"/best-website-services-in-surat"} style={{ textDecoration: "none", color: "#388087" }}>
                                            <img src={s1} className="me-3" alt="s1" />
                                            Website Development
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#action4" className="subMenu">
                                        <Link to={"/best-application-services-in-surat"} style={{ textDecoration: "none", color: "#388087" }}>
                                            <img src={s2} className="me-3" alt="s2" />
                                            App Development
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#action3" className="subMenu">
                                        <Link to={"/best-software-services-in-surat"} style={{ textDecoration: "none", color: "#388087" }}>
                                            <img src={s3} className="me-3" alt="s3"/>
                                            Software Development
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#action4" className="subMenu">
                                        <Link to={"/best-designing-in-surat"} style={{ textDecoration: "none", color: "#388087" }}>
                                            <img src={s4} className="me-3" alt="s4" />
                                            UI/UX & Graphic Design
                                        </Link>
                                    </NavDropdown.Item>
                                </NavDropdown>
                                {/* <CustomDropdownToggle url={url} /> */}
                            </div>


                            <Nav.Link href="#action1" className="Menus mainMenu">
                                <Link to={"/best-company-for-technology"} className={`Menus ${url == "Technologies" ? "Active" : ""}`}>Technologies</Link>
                            </Nav.Link>

                            <Nav.Link className="Menus mainMenu">
                                <Link to={"/Portfolio"} className={`Menus ${url == "Portfolio" ? "Active" : ""}`}>Portfolio</Link>
                            </Nav.Link>

                            <Nav.Link className="Menus mainMenu">
                                <Link to={"/best-job-opportunities"} className={`Menus ${url == "Hiring" ? "Active" : ""}`}>Hiring</Link>
                            </Nav.Link>

                            <div className="mainsubMenu order-lg-last" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                <NavDropdown title="Company" className={`Menus mainMenu ${url == "Company" ? "Active" : ""}`} show={isDropdownOpen} id="navbarScrollingDropdown">
                                    <NavDropdown.Item className="subMenu">
                                        <Link to={"/AboutSnepitech"} style={{ textDecoration: "none", color: "#388087" }}>
                                            <img src={c1} className="me-3" alt="c1" />
                                            About Us
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="subMenu">
                                        <Link to={"/best-services-for-website-and-applications"} style={{ textDecoration: "none", color: "#388087" }}>
                                            <img src={c2} className="me-3" alt="c2" />
                                            Why Snepitech
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="subMenu">
                                        <Link to={"/GrowthAtSnepitech"} style={{ textDecoration: "none", color: "#388087" }}>
                                            <img src={c3} className="me-3" alt="c3"/>
                                            Careers
                                        </Link>
                                    </NavDropdown.Item>
                                </NavDropdown>
                                {/* <CustomDropdownToggle1 url={url} /> */}
                            </div>
                        </Nav>
                        <Form className="d-flex justify-content-center">
                            <Link to={"/RequestQuote"} style={{ textDecoration: "none", color: "#388087" }}>
                                <Button variant="" className="HeaderButton">
                                    Request for Quote
                                    <img src={Arrow} className="ms-3 arrow" alt="Arrow" />
                                </Button>
                            </Link>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}
export default Header;

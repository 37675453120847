import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import BeforeFooter from "../Component/BeforeFooter";
import Footer from '../Component/Footer';
import 'animate.css';
import WOW from 'wowjs';

import { Col, Container, Modal, Row } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './GrowthAtSnepitech.css';

import Rounds from "../Images/Home/Rounds.webp";
import Rounds2 from "../Images/Home/Round2.webp";

import ArrowWhite from "../Images/Home/ArrowWhite.png";
import ArrowBlack from "../Images/Home/ArrowBlack.png";
import Arrow from "../Images/Home/Arrow.png";

import footeruplode from "../Images/Footer/Vector.png";

import team1 from "../Images/Careers/PB1.jpg";
import team2 from "../Images/Careers/team2.png";

import cv from "../Images/Careers/contentWriter.png";
import dm from "../Images/Careers/DM.png";
import hr from "../Images/Careers/HR.png";

import js1 from "../Images/Careers/1.png";
import js2 from "../Images/Careers/2.png";
import js3 from "../Images/Careers/3.png";
import js4 from "../Images/Careers/4.png";
import js5 from "../Images/Careers/5.png";
import js6 from "../Images/Careers/6.png";
import { Link } from "react-router-dom";

import Flutter from "../Images/Services/Flutter.png";
import reactjsicon from "../Images/Services/Angular.png";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { PiMouseSimple } from "react-icons/pi";
import { toast, ToastContainer } from "react-toastify";
import { Api } from "../Api/Api";
import moment from "moment";
import Seo from "../Component/Seo";
const { EmpWeb } = Api;

async function EmpWebInsert(credentials) {
    return fetch(EmpWeb.Insert, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}



function GrowthAtSnepitech() {

    const [getStarted, setGetStarted] = useState(false);

    const [designation, setDesignation] = useState("");
    const [experience, setExperience] = useState("");
    const [fName, setFname] = useState("");
    const [Lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [city, setCity] = useState("");
    const [Message, setMessage] = useState("");
    const [CV, setCV] = useState("");
    const [scrollmouseClass, setScrollmouseClass] = useState("notShown");

    const applyFn = async () => {
        if (fName == "") {
            toast.error("First Name is Required.")
        } else if (Lname == "") {
            toast.error("Last Name is Required.")
        } else if (email == "") {
            toast.error("Email is Required.")
        } else if (phone == "") {
            toast.error("Mobile Number is Required.")
        } else if (city == "") {
            toast.error("City Name is Required.")
        } else if (designation == "") {
            toast.error("Designation is Required.")
        } else if (experience == "") {
            toast.error("Expirience is Required.")
        } else {
            const data = await EmpWebInsert({
                name: fName,
                lastname: Lname,
                email: email,
                mobilenumber: parseInt(phone),
                city: city,
                experience: parseInt(experience),
                message: Message,
                designation: designation,
                qtype: "Insert",
                joiningdate: moment().format("DD-MM-YYYY"),
            })
            if (data) {
                toast.success("Details Send Successfully..")
            }
            await resetFn();
        }
    }


    const resetFn = () => {
        setFname("");
        setLname("");
        setEmail("");
        setPhone("")
        setCity("")
        setCV("");
        setMessage("");
        // setDesignation("");
        // setExperience("");
    }

    const handleScroll = () => {
        const currentScrollTop = window.scrollY;
        const showThreshold = 400; // Adjust this threshold as needed

        if (currentScrollTop > showThreshold) {
            setScrollmouseClass("Shown");
        } else {
            setScrollmouseClass("notShown");
        }
    };

    const scrollevent = () => {
        const currentScrollTop = window.scrollY;
        if (currentScrollTop > 400) {
            window.scrollTo(0, 0);
        }
    };

    const scrolleventbutton = () => {
        window.scrollTo(0, 1500);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const SnepiTeam = [
        {
            img: team1,
            name: "Priyank Gadhiya",
            designation: "Founder & CTO"
        },
        // {
        //     img: team2,
        //     name: "Janvi Gadhiya",
        //     designation: "Founder & CEO"
        // },
    ]

    const SPArray = [
        {
            img: Flutter,
            position: "Flutter Developers",
            Experience: "2-4 Year",
            numberofOpening: "2",
            Qualification: "Graduate"
        },
        {
            img: reactjsicon,
            position: "ReactJS Developers",
            Experience: "0-5 Year",
            numberofOpening: "5",
            Qualification: "Graduate"
        }
    ]

    const JSArray = [
        {
            img: js1,
            head: "Growth-Focused",
            para: "We keep employee growth in mind, with each development step and project we take up. "
        },
        {
            img: js2,
            head: "Bonus & Compensations",
            para: "Timely appraisals, bonus and compensations are made based on performance."
        },
        {
            img: js3,
            head: "Flexible Working Hours",
            para: "Flexible work timing increases productivity and does not include stress for the team members."
        },
        {
            img: js4,
            head: "Paid Overtime",
            para: "Deadlines are important, and we compensate for each deadline met through overtime appreciation."
        },
        {
            img: js5,
            head: "Celebrations",
            para: "Celebrations for festivals and social days is a routine at Snepitech."
        },
        {
            img: js6,
            head: "Games & Activities",
            para: "We play a variety of games and organize activities for a relaxed and stress-free work culture. "
        },
    ]

    const handlePhoneChange = (e) => {
        const input = e.target.value;
        const numericInput = input.replace(/\D/g, '');
        setPhone(numericInput);
    }

    useEffect(() => {
        new WOW.WOW().init();
    }, []);

    return (
        <>
     <Seo
        title="GROWTH OUR BUSINESS " 
        description="description " 
        keywords="GROWTH ,passionate ,Open ,position , hire "
      />
        <div onScroll={handleScroll}>
            <div className={`upperMouse ${scrollmouseClass}`}>
                <PiMouseSimple className="upperMouseIcon" onClick={scrollevent} />
            </div>
            <div className={`MainHome MainHome1`}>
                <Header />
                <Container>
                    <Row className="wow animate__animated animate__fadeInUp"
                        style={{ animationDuration: '2s' }}>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="mt-5 pt-5">
                            <h1 className="ServiceSliderHead">
                                GROWTH AT SNEPITECH
                            </h1>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="py-5">
                            <h3 className="ServiceSliderPara">
                                Are you a developer, designer, or passionate about providing solutions? If yes, then Snepitech is the right place for your career growth. We are welcoming talented professionals from across the globe to work with us.
                            </h3>
                        </Col>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <Link to={""} style={{ textDecoration: "none" }}>
                                <button variant="outline-success" className="AboutButton AboutButtonGrowth" onClick={scrolleventbutton}>
                                    Check Current Openings
                                    <img src={ArrowWhite} alt="ArrowWhite" className="ms-3 arrow" />
                                </button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="mt-5 Line3Parent">
                <Container fluid>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div className="AboutServices3">
                                <div className="Rounds"><img src={Rounds} alt="rounds" /></div>
                                <div className="Rounds2"><img src={Rounds2} alt="rounds2" /></div>
                            </div>
                            <Container>
                                <Row>
                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                        <h1 className="ProcessHead1 wow animate__animated animate__fadeInLeft" style={{ animationDuration: '2s' }}>Team Snepitech</h1>
                                    </Col>
                                </Row>

                                <Row className="teamMain">
                                    {
                                        SnepiTeam.map((i) => {
                                            return (
                                                <Col xxl="4" xl="4" lg="4" md="6" sm="12" xs="12" className="mt-3">
                                                    <div className="teamParent  wow animate__animated animate__fadeInDown" style={{ animationDuration: '2s' }}>
                                                        <div className="imgparent">
                                                            <img src={i.img} alt={i.img} />
                                                        </div>
                                                        <div className="details">
                                                            <h4>{i.name}</h4>
                                                            <p>{i.designation}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="Process mt-5 pt-5">

                <Container>
                    <div className="AboutHome2">
                        <div className="Roundss"><img src={Rounds} alt="rounds" /></div>
                        <div className="Roundss2"><img src={Rounds2} alt="rounds2" /></div>
                    </div>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <h1 className="ProcessHead wow animate__animated animate__fadeInLeft" style={{ animationDuration: '2s' }}>Current Open Positions At Snepitech</h1>
                        </Col>
                    </Row>
                    <Row className="snepitechPositions">
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            {SPArray.map((SP, index) => {
                                let AnimationType;
                                if (index % 2 == 1) {
                                    AnimationType = "fadeInUp"
                                } else {
                                    AnimationType = "fadeInDown"
                                }
                                return (
                                    <div className={`mainSnepiPositions wow animate__animated ${'animate__' + AnimationType}`} style={{ animationDuration: '1s' }}>
                                        <Row >
                                            <Col xxl="1" xl="1" lg="1" md="2" sm="2" xs="12" className="px-0 snepipositionsmall">
                                                <div className="imgParentSP d-flex justify-content-center">
                                                    <img src={SP.img} alt={SP.img}/>
                                                </div>
                                            </Col>
                                            <Col xxl="9" xl="10" lg="9" md="7" sm="7" xs="12" className="px-0 d-flex align-items-center snepipositionsmall">
                                                <div className="details">
                                                    <h2 className="spPosition">
                                                        {SP.position}
                                                    </h2>
                                                    <Row className="d-flex">
                                                        <Col className="d-flex borderRight center1" xxl="3" xl="4" lg="4" md="6" sm="12" xs="12">
                                                            <p className="sphead mt-0">
                                                                Experience : &nbsp;
                                                            </p>
                                                            <p className="spvalue mt-0">
                                                                {SP.Experience}
                                                            </p>
                                                        </Col>

                                                        <Col className="d-flex borderRight center2" xxl="3" xl="4" lg="4" md="6" sm="12" xs="12">
                                                            <p className="sphead mt-0">
                                                                Number of Opening : &nbsp;
                                                            </p>
                                                            <p className="spvalue mt-0">
                                                                {SP.numberofOpening}
                                                            </p>
                                                        </Col>

                                                        <Col className="d-flex center2" xxl="3" xl="4" lg="4" md="6" sm="12" xs="12">
                                                            <p className="sphead mt-0">
                                                                Qualifications : &nbsp;
                                                            </p>
                                                            <p className="spvalue mt-0">
                                                                Graduate
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col xxl="2" xl="1" lg="2" md="3" sm="3" xs="12" className="d-flex align-items-center justify-content-end px-0">
                                                <button variant="outline-success" className="AboutButton AboutButtonSP" onClick={() => { setGetStarted(true); setDesignation(SP.position); setExperience(SP.Experience); }}>
                                                    Apply Now
                                                    <img src={Arrow} alt="Arrow" className="ms-3 arrow arrowsmall1" />
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}

                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="mt-5 Line3Parent">
                <Container fluid>
                    <Row>
                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                            <div className="AboutServices3">
                                {/* <div className="Rounds"><img src={Rounds} alt="rounds" /></div>
                                <div className="Rounds2"><img src={Rounds2} alt="rounds2" /></div> */}
                            </div>
                            <Container>
                                <Row>
                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                        <h1 className="ProcessHead1 wow animate__animated animate__fadeInLeft" style={{ animationDuration: '2s' }}>Why Join Snepitech?</h1>
                                    </Col>
                                </Row>
                                <Row className="jsMainSec">
                                    {
                                        JSArray.map((JS, index) => {
                                            let AnimationType;
                                            if (index % 2 == 1) {
                                                AnimationType = "fadeInRight"
                                            } else {
                                                AnimationType = "fadeInLeft"
                                            }
                                            return (
                                                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className="px-4 py-3">
                                                    <div className={`jsMain wow animate__animated ${'animate__' + AnimationType}`} style={{ animationDuration: '2s' }}>
                                                        <Row>
                                                            <Col xxl="2" xl="2" lg="3" md="12" sm="12" xs="12" className="imgcol">
                                                                <div className="jsImg">
                                                                    <img src={JS.img} alt={JS.img}/>
                                                                </div>
                                                            </Col>
                                                            <Col xxl="10" xl="10" lg="9" md="12" sm="12" xs="12" className="jsDetails">
                                                                <h3 className="jshead">{JS.head}</h3>
                                                                <p className="jspara">{JS.para}</p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>

            <BeforeFooter />

            <Footer />

            <Modal size="xl" aria-labelledby="example-modal-sizes-title-sm" centered className="text-center mt-5" show={getStarted} id="modelSize">
                <Modal.Body>

                    <AiOutlineCloseCircle style={{ color: "#388087" }} onClick={() => { setGetStarted(false) }} className="closeBtn" />
                    <Container>

                        <Row>
                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="py-2">
                                <div className="FieldBody">

                                    <Row>
                                        <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className="ps-3 d-flex align-items-center justify-content-start">
                                            <label className="d-flex align-items-center justify-content-start inputLabel">Name <sup style={{ color: "#F00" }}><h6 className="mb-0">*</h6></sup></label>
                                        </Col>
                                        <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12" className="px-0">
                                            <Row>
                                                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                                    <input type="text" className="inputfield" placeholder="First Name *" value={fName} onChange={(e) => { setFname(e.target.value) }} />
                                                </Col>
                                                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                                    <input type="text" className="inputfield" placeholder="Last Name *" value={Lname} onChange={(e) => { setLname(e.target.value) }} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className="ps-3 d-flex align-items-center justify-content-start">
                                            <label className="d-flex align-items-center justify-content-start inputLabel">Email <sup style={{ color: "#F00" }}><h6 className="mb-0">*</h6></sup></label>
                                        </Col>
                                        <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12" className="px-0">
                                            <input type="text" className="inputfield" placeholder="Enter Your Valid Email " value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className="ps-3 d-flex align-items-center justify-content-start">
                                            <label className="d-flex align-items-center justify-content-start inputLabel">Mobile Number <sup style={{ color: "#F00" }}><h6 className="mb-0">*</h6></sup></label>
                                        </Col>
                                        <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12" className="px-0">
                                            <input type="text" className="inputfield" placeholder="Enter Your Valid Mobile Number " value={phone} onChange={(e) => { handlePhoneChange(e) }} maxLength={10} minLength={10} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className="ps-3 d-flex align-items-center justify-content-start">
                                            <label className="d-flex align-items-center justify-content-start inputLabel">City <sup style={{ color: "#F00" }}><h6 className="mb-0">*</h6></sup></label>
                                        </Col>
                                        <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12" className="px-0">
                                            <input type="text" className="inputfield" placeholder="Enter City Name " value={city} onChange={(e) => { setCity(e.target.value) }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className="ps-3 d-flex align-items-center justify-content-start">
                                            <label className="d-flex align-items-center justify-content-start inputLabel">Experience <sup style={{ color: "#F00" }}><h6 className="mb-0">*</h6></sup></label>
                                        </Col>
                                        <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12" className="px-0">
                                            <input type="text" className="inputfield" placeholder="Enter Experience " disabled value={experience} onChange={(e) => { setExperience(e.target.value) }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className="ps-3 d-flex align-items-center justify-content-start">
                                            <label className="d-flex align-items-center justify-content-start inputLabel">Position <sup style={{ color: "#F00" }}><h6 className="mb-0">*</h6></sup></label>
                                        </Col>
                                        <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12" className="px-0">
                                            <input type="text" className="inputfield" placeholder="Enter Position " disabled value={designation} onChange={(e) => { setDesignation(e.target.value) }} />
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className="ps-3 d-flex align-items-center justify-content-start">
                                            <label className="d-flex align-items-center justify-content-start inputLabel">Joining Date <sup style={{ color: "#F00" }}><h6 className="mb-0">*</h6></sup></label>
                                        </Col>
                                        <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12" className="px-0 calIconRelative">
                                            <DatePicker selected={joiningDate} className="inputfield" placeholderText="Select Date" onChange={(date) => setJoiningDate(date)} />
                                            <FaCalendarAlt className="calIcon" />
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className="ps-3 d-flex align-items-center justify-content-start">
                                            <label className="d-flex align-items-center justify-content-start inputLabel">Message</label>
                                        </Col>
                                        <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12" className="px-0">
                                            {/* <input type="text" className="inputfield" placeholder="Enter Message " /> */}
                                            <textarea className="inputfield" rows={4} placeholder="Enter Message" value={Message} onChange={(e) => { setMessage(e.target.value) }}></textarea>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className="ps-3 d-flex align-items-center justify-content-start">
                                            <label className="d-flex align-items-center justify-content-start inputLabel">Upload CV <sup style={{ color: "#F00" }}><h6 className="mb-0">*</h6></sup></label>
                                        </Col>
                                        <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12" className="px-0 inputRelativeFile">
                                            <input type="text" className="inputfield inputFieldFile" placeholder="Choose file " />
                                            <span className="uplodeIcon">
                                                <img src={footeruplode} alt={footeruplode}/>
                                            </span>
                                            <span className="uplodeIcon">
                                                <input type="file" className="opacity0" />
                                            </span>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className="ps-3 d-flex align-items-center justify-content-start">
                                            <label className="d-flex align-items-center justify-content-start inputLabel">Upload Workflow</label>
                                        </Col>
                                        <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12" className="px-0 inputRelativeFile">
                                            <input type="text" className="inputfield inputFieldFile" placeholder="Choose file " />
                                            <span className="uplodeIcon">
                                                <img src={footeruplode} />
                                            </span>
                                            <span className="uplodeIcon">
                                                <input type="file" className="opacity0" />
                                            </span>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-end mt-3">
                                            <button variant="outline-success" className="AboutButton ButtonFooterPopUp me-2" onClick={resetFn}>
                                                Reset
                                            </button>
                                            <button variant="outline-success" className="AboutButton ButtonFooterPopUp" onClick={() => { applyFn() }}>
                                                Apply Now
                                                <img src={ArrowWhite} alt="ArrowWhite" className="ms-2 arrow arrowfooterPopup" />
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <ToastContainer
                position="bottom-left"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={1}
            />
        </div >
        </>
    )
}
export default GrowthAtSnepitech;